import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import getAuthSession from '../../services/auth';
import { Workflow } from '../../interfaces/workflow.interface';
import { Survey } from '../../interfaces/survey.interface';
import { Assessment } from '../../interfaces/assessment.interface';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { wfHierarchy } from '../../services/workflow';
import { QuestionnaireTypes } from '../../services/questionnaires';

interface InitialWorkflowState {
  workflows?: Workflow[];
  workflow?: Workflow;
  baseline?: Survey;
}

const initialState = {
  workflow: undefined,
  baselines: undefined,
} as InitialWorkflowState;

const baseUrl = process.env.REACT_APP_BASE_API;

export type FetchAllWorkflowsRequest = {
  created_at?: string;
  updated_at?: string;
  baseline?: number;
  bp_survey?: number;
  assessment?: number;
  assessment_survey?: number;
  service_provider?: number;
  assessor?: number;
  assigned_assessor?: number;
  site?: number;
  status?: string;
  workflow_type?: number;
  application?: number;
  version?: number;
  is_active?: boolean;
  badges?: string;
  site_ids?: string;
  application_version_ids?: string;
  search?: string;
  ordering?: string;
  limit?: number;
  offset?: number;
};

export const fetchAllWorkflows = createAsyncThunk(
  'workflow/fetchAllWorkflows',
  async ({ is_active: isActive = true, limit = 9999, offset = 0, ...rest }: FetchAllWorkflowsRequest, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const queryString = Object.entries(rest).filter(([_, val]) => val !== undefined && (_ || !_)).map(([key, value]) => `${key}=${value}`).join('&');
      let url = `${baseUrl}/workflows/?is_active=${isActive}&offset=${offset}&limit=${limit}${queryString ? `&${queryString}` : ''}`;
      const response = await axios.get(
        url,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results as Workflow[];
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchWorkflow = createAsyncThunk(
  'workflow/fetchWorkflow',
  async (request: { survey: Survey, assessment?: Assessment }, { rejectWithValue }) => {
    try {
      const { survey, assessment } = request;
      const authSession = await getAuthSession();
      let url = `${baseUrl}/workflows`;
      if (assessment) {
        url += `/?bp_survey=${survey.id}&assessment=${assessment.id}`;
      } else {
        switch ((survey.questionnaire as Questionnaire).type) {
          case QuestionnaireTypes.APPLICATION_BASELINE:
          case QuestionnaireTypes.SITE_BASELINE:
            url += `/?baseline=${survey.id}`;
            break;
          case QuestionnaireTypes.MPA_BEST_PRACTICE:
            url += `/?bp_survey=${survey.id}`;
            break;
        }
      }
      url += '&ordering=-created_at';

      const response = await axios.get(
        url,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results[0] as Workflow;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchBaselineSurvey = createAsyncThunk(
  'workflow/fetchBaselineSurvey', 
  async (request: { id: number }, { rejectWithValue }) => {
    try {
      const { id } = request;
      const authSession = await getAuthSession();

      let url = `${baseUrl}/surveys/${id}`;

      const response = await axios.get(
        url,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      
      return response.data as Survey;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateWorkflow = createAsyncThunk(
  'workflow/updateWorkflow',
  async (request: Partial<Workflow> & { adminOverride?: boolean }, { rejectWithValue }) => {
    try {
      const { id } = request;
      let req = { ...request };
      const authSession = await getAuthSession();
      // Revert button includes adminOverride
      if (!req.adminOverride) {
        const currentWorkflow = await axios.get(
          `${process.env.REACT_APP_BASE_API}/workflows/${id}/`,
          {
            headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
          },
        );

        const currentStatus = currentWorkflow.data?.status;

        const currentStatusIndex = wfHierarchy.indexOf(currentStatus || '');
        const newStatusIndex = wfHierarchy.indexOf(req.status || '');
        const statusExceptions = (currentStatus === 'complete' && req.status === 'incomplete') || (currentStatus === 'non-compliant' && req.status === 'incomplete');
        // If the new status is lower in the hierarchy, just use the current status unless in pre-assessment phase
        if (newStatusIndex < currentStatusIndex && !statusExceptions) {
          req.status = currentStatus;
        }
      }
      const response = await axios.patch(
        `${baseUrl}/workflows/${id}/`, req,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Workflow;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    resetWorkflow: (state) => {
      state.baseline = undefined;
      state.workflows = undefined;
      state.workflow = undefined;
    },
  },
  extraReducers: builder => {
    // for fetching LIST of workflows
    builder.addCase(fetchAllWorkflows.fulfilled, (state, { payload }: PayloadAction<Workflow[]>) => {
      state.workflows = payload;
    });

    // for fetching SINGLE whole workflow data
    builder.addCase(fetchWorkflow.fulfilled, (state, { payload }: PayloadAction<Workflow>) => {
      state.workflow = payload;
    });

    // for updating workflow
    builder.addCase(updateWorkflow.fulfilled, (state, { payload }: PayloadAction<Workflow>) => {
      if (state.workflows) {
        const newWF = [...state.workflows];
        const index = newWF.findIndex(wf => wf.id === payload.id);
        if (index >= 0) {
          newWF[index] = payload;
          state.workflows = newWF;
        }
      }
      state.workflow = payload;
    });

    // for baseline
    builder.addCase(fetchBaselineSurvey.fulfilled, (state, { payload }: PayloadAction<Survey | undefined>) => {
      state.baseline = payload;
    });
  },
});

export const { resetWorkflow } = workflowSlice.actions;

export default workflowSlice.reducer;
