import React from 'react';
import { Popover, Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import { Notification } from '../../interfaces/assessment.interface';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useTheme } from '@mui/material';

export default function NotificationPopover({
  anchorEl,
  open,
  onClose,
  notifications,
  handleBPClick,
}: {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  notifications: Notification[];
  handleBPClick: (bp: any, notificationId: number) => void;
}) {
  const theme = useTheme();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: { 
          width: 550,
          maxHeight: 550,
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ p: 0 }}>
        <Typography variant="h6" sx={{ mb: 1, px: 2, pt: 2, fontWeight: 500, color: theme.palette.customThemeColors?.darkGrey }}>
          Assessment Notifications
        </Typography>
        <Divider sx={{ p: 0, m: 0 }} />
        {notifications?.length === 0 && (
            <Typography
                variant="body2"
                sx={{ px: 3, py: 2, textAlign: 'center', fontWeight: 500, color: theme.palette.customThemeColors?.darkGrey }}
            >
                No new notifications
            </Typography>
        )}
        <Box sx={{ maxHeight: 450, overflowY: 'auto', outline: 'none' }} tabIndex={0}>
          <List sx={{ p: 0, m: 0 }}>
            {notifications.map((notification, index) => {
              const today = new Date();
              const notificationDate = new Date(notification.date);

              // Calculate the time difference in ms
              const diffInMs = today.getTime() - notificationDate.getTime();

              // Convert time differences
              const daysAgo = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
              const hoursAgo = Math.floor(diffInMs / (1000 * 60 * 60));
              const minutesAgo = Math.floor(diffInMs / (1000 * 60));

              // Decide what to display based on the time difference
              let displayTime;
              if (daysAgo > 0) {
                displayTime = `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
              } else if (hoursAgo > 0) {
                displayTime = `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
              } else {
                displayTime = `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
              }
              const TPNLogoSmall = `${process.env.PUBLIC_URL}/assets/TPN_logo_small.png`;
              return (
                <ListItem
                  key={index}
                  onClick={() => notification.bestPractice && handleBPClick(notification.bestPractice, notification.id)}
                  sx={{ '&:hover': { backgroundColor: '#F3F4F5' }, px: 2, my: '2px' }}
                >
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'start', width: '100%' }}>
                  <Box sx={{ display: 'flex', mt: 1, width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#4B486E', overflow: 'hidden', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Future - Should use company icon instead if we have company action triggered notifications */}
                    <img
                      src={TPNLogoSmall}
                      alt="Logo"
                      style={{
                        width: '110%',
                        height: '110%',
                        objectFit: 'contain',
                        borderRadius: '50%',
                        padding: '0px',
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <ListItemText
                      sx={{ cursor: 'pointer', py: 0, flex: '1' }}
                      primary={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%' }}>
                        <Box>
                            <Typography component="span" variant="body2" fontWeight="500">
                            {notification.type}
                            </Typography>
                            {' '}
                            <Typography component="span" variant="caption" color="textSecondary">
                            - {displayTime}
                            </Typography>
                            <Typography variant="body2" sx={{ display: 'block', mt: 0.5, '&:hover': { textDecoration: 'underline' } }}>
                            {notification.questionTitle}
                            </Typography>
                        </Box>
                        {!notification.read && (
                          <FiberManualRecordIcon sx={{ color: 'primary.main', mt: '9px', fontSize: '9px', ml: '10px' }} />
                        )}
                      </Box>
                    }
                    />
                    </Box>
                </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </Popover>
  );
}