// npm
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import Confetti from 'react-confetti';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import { QuestionStatus, setAllAssessmentQuestionStatus, setNextRemediationQuestion } from '../../../app/store/questionnaireAnswerSlice';
import { Box, Button, Card, Collapse, darken, Divider, Grid, lighten, Typography, useTheme, Snackbar, Alert } from '@mui/material';
import { Check, NavigateNext, Warning, Done } from '@mui/icons-material';
import { handleSaveAssessorQuestionAnswer, handleSaveSubRemediations } from '../../../services/assessments';
import Slide from '@mui/material/Slide';
// hooks, slice, and interface
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  setQuestion,
  nextQuestion,
  setQuestionAnswer,
  PaletteKey,
  setReadOnly,
  setCompanyCertifications,
  setAssessmentQuestionStatus,
  setAuditLogs,
  setQuestionAuditLogFetched,
  setEvidence,
  setComments as setStoreComments,
} from '../../../app/store/questionnaireAnswerSlice';
import { AuditLog } from '../../../interfaces/auditLog.interface';
import { Question } from '../../../interfaces/question.interface';
import { Questionnaire } from '../../../interfaces/questionnaire.interface';
import User, { Badge, Company, Version } from '../../../interfaces/users.interface';
import { Evidence, QuestionAnswer } from '../../../interfaces/questionAnswer.interface';
import { AssessmentSurvey, Comment, PIDetails, SubRemediation } from '../../../interfaces/assessment.interface';
import { CertificationControl } from '../../../interfaces/certificationControl.interface';
import { InclusionConfiguration } from '../../../interfaces/inclusionConfiguration.interface';
import { REMEDIATION_OPTIONS } from '../../../services/surveyHelpers';
import { QuestionnaireTypes } from '../../../services/questionnaires';
import AssessorPIDetails from '../../../features/Questions/AssessorPIDetails';
// functions
import { getMultipleComments } from '../../../services/assessments';
import { buildBestPracticeTitle } from '../../BestPractices/BestPractice';
import { shouldUseAssessmentColors } from '../../../services/questionnaireQuestionStatus';
import { createAuditLog, createComment, getAuditLogByQuestionAnswerId } from '../../../services/commentsLogs';
import createQuestionAnswer, {
  deleteEvidence,
  getManyEvidence,
  updateEvidences,
  updateQuestionAnswer,
  uploadMultipleAttachments,
  processFiles,
} from '../../../services/questionAnswer';
//components
import Comments from '../../Comments/Comments';
import RemediationForm from './RemediationForm';
import AuditLogModal from '../../AuditLog/AuditLogModal';
import AssessorFindingsForm from './AssessorFindingsForm';
import FileUploaderComp, { FileUpload } from '../../Forms/FileUpload';
import AttachmentFormAndComment from './AttachmentAssessmentAndComment';
import QuestionOnQuestionnaireAnswer from './QuestionOnQuestionnaireAnswer';
import QuestionOnQuestionnaireHeader from './QuestionOnQuestionnaireHeader';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import CompanyCertificationForm from '../../Directory/Certifications/CompanyCertificationForm';
import { getQuestion } from '../../../services/question';

const ColorMap: { [key: string]: PaletteKey } = {
  incomplete: 'info',
  complete: 'success',
  remediation: 'error',
  submitted: 'warning',
  cert: 'primary',
  priority: 'secondary',
  'non-compliant': 'nonCompliant',
  remediationComplete: 'primary',
};

const QuestionOnQuestionnaire = (props: { question: Question; comments?: boolean, relavantQuestionStatus?: QuestionStatus }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { assessment } = useAppSelector((state) => state.assessments);
  const { survey, surveys } = useAppSelector((state) => state.surveys);
  const {
    certControlsById,
    question: questionItem,
    questionStatus,
    companyCerts,
    questions,
    bestPractice,
    bpStatus,
    topic,
    domain,
    preview,
    questionnaire,
    readOnly,
    submitted,
    assessmentStatus,
    assessmentQuestionsStatus,
    comments: allComments,
    auditLogs,
    evidenceById,
    company,
  } = useAppSelector((state) => state.questionnaireAnswer);
  const { applicationVersions } = useAppSelector((state) => state.serviceCategories);
  const [question, setCurrentQuestion] = useState(props.question);
  const { workflow } = useAppSelector(state => state.workflow);
  const blueBadge = useMemo(() => (workflow?.badges as Badge[])?.find(b => b.title === 'tpn_self_reported'), [workflow]);
  const [curQuestionStatus, setCurStatus] = useState<string>(props.relavantQuestionStatus?.status ? props.relavantQuestionStatus.status : 'incomplete');
  const [showPIDetails, setShowPIDetails] = useState(false);
  const [isValidCORemPlan, setIsValidCORemPlan] = useState<boolean>(false);
  const [selectedCtrlAns, setSelectedCtrlAns] = useState<{ [key: number]: PIDetails }>({});
  const {
    register,
    handleSubmit,
    //formState,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      text: undefined,
      answer: undefined,
      nonApplicableAnswers: undefined,
      compensatingControlAnswers: undefined,
      nonImplementedAnswers: undefined,
      comment: undefined,
    } as { text?: string; nonImplementedAnswers?: number[] | number, compensatingControlAnswers?:  number[] | number, nonApplicableAnswers?:  number[] | number, answer?: number[] | number; comment?: string },
  });

  useEffect(() => {
    if (!user || readOnly || !questionnaire) return;
    if (user.type !== 'vendor_admin' && questionnaire.type === QuestionnaireTypes.MPA_BEST_PRACTICE) dispatch(setReadOnly(true));
  }, [user, questionnaire]);

  const [certificationControl, setCertificationControl] = useState<CertificationControl | undefined>(undefined);
  const [curQuestionAnswer, setCurQuestionAnswer] = useState<QuestionAnswer | undefined>(undefined);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [subRemVFiles, setSubRemVFiles] = useState<{ [key: string]: FileUpload[] }>({});
  const [subRemNVFiles, setSubRemNVFiles] = useState<{ [key: string]: FileUpload[] }>({});
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [nfiles, setNFiles] = useState<FileUpload[]>([]);
  const [vfiles, setvFiles] = useState<FileUpload[]>([]);
  const [nvfiles, setNvFiles] = useState<FileUpload[]>([]);
  const [comments, setComments] = useState<Comment[] | undefined>(undefined);
  const [lastUpdated, setLastUpdated] = useState<AuditLog[] | undefined>(undefined);
  const [color, setColor] = useState<PaletteKey>(() => {
    const { question: currentQuestion, relavantQuestionStatus } = props;
  
    if (currentQuestion && relavantQuestionStatus?.status) {
      const status = relavantQuestionStatus.status;
      if (assessmentQuestionsStatus && assessmentQuestionsStatus[currentQuestion.id]?.vendorStatus === REMEDIATION_OPTIONS.remediated) {
        return 'primary';
      }
      return ColorMap[status] || 'info';
    }
  
    return 'info';
  });
  const [inclusion, setInclusion] = useState<InclusionConfiguration | undefined>(undefined);
  const [waitForNext, setWaitForNext] = useState(false);
  const [assessorFinding, setAssessorFinding] = useState('');
  const [assessorAnswer, setAssessorAnswer] = useState('');
  const [vendorStatus, setVendorStatus] = useState('');
  const [remediationComment, setRemediationComment] = useState('');
  const [remediationDate, setRemediationDate] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [auditLogKill, setAuditLogKill] = useState(false);
  const [isAssessmentFindingsOpen, setIsAssessmentFindingsOpen] = useState(false);
  const [subRemErr, setSubRemErr] = useState<string>('');
  const [remediationDateErr, setRemediationDateErr] = useState<string>('');
  const [stepIndex, setStepIndex] = React.useState<number>(0);

  const [bpComplete, setBPCompleteOpen] = useState(false);
  const handleBPCompleteOpen = () => setBPCompleteOpen(true);
  const handleBPCompleteClose = () => setBPCompleteOpen(false);
  const [commentModal, setCommentModalOpen] = useState(false);
  const handleCommentModalOpen = () => setCommentModalOpen(true);
  const handleCommentModalClose = () => setCommentModalOpen(false);
  const [auditLogModal, setAuditLogModalOpen] = useState(false);
  const handleAuditLogModalOpen = () => setAuditLogModalOpen(true);
  const handleAuditLogModalClose = () => setAuditLogModalOpen(false);
  const [assessorFindingModal, setAssessorFindingModalOpen] = useState(false);
  const handleAssessorFindingModalOpen = () => setAssessorFindingModalOpen(true);
  const [subRemediations, setSubRemediations] = useState<SubRemediation[]>([]);
  const setSubRemediationFromAssessorQuestionAnswers = useCallback(() => {
    if (!assessmentQuestionsStatus || !question.id || !assessmentQuestionsStatus[question.id]) return;
    
    const { assessorQuestionAnswers, remediationQuestionAnswers } = assessmentQuestionsStatus[question.id];

    if (remediationQuestionAnswers && remediationQuestionAnswers.length > 0) {
      const subRems = [...remediationQuestionAnswers].map((remediation) => ({
        id: remediation.id,
        assessorAnswer: remediation.assessorAnswer,
        remediationPlan: remediation.remediationPlan,
        remediationComment: remediation.remediationComment,
        remediationDate: remediation.remediationDate,
        answer: typeof remediation.answer === 'number' ? remediation.answer : remediation.answer.id,
        text: typeof remediation.answer !== 'number' ? '' : question.answers.find((answer) => answer.id === remediation.answer)?.text,
        attachments: remediation.attachments,
      }));

      subRems.sort((a, b) => {
        const answerA = typeof a.answer === 'number' ? a.answer : 0;
        const answerB = typeof b.answer === 'number' ? b.answer : 0;
        return answerA - answerB;
      });
      setSubRemediations(subRems);
      return;
    }

    const tmpSubRemediations = assessorQuestionAnswers?.reduce((acc, it) => {
      if (it.compensatingOrNotApplicable === 'implemented') return acc;
      acc.push({
        answer: it.answer,
        text: question.answers.find((answer) => answer.id === it.answer)?.text,
        remediationPlan: '',
        remediationComment: '',
        remediationDate: new Date().toISOString(),
        assessorAnswer: it.assessorAnswer as number,
      });
      return acc;
    }, [] as SubRemediation[]);

    if (tmpSubRemediations?.length) {
      tmpSubRemediations.sort((a, b) => {
        const answerA = typeof a.answer === 'number' ? a.answer : 0;
        const answerB = typeof b.answer === 'number' ? b.answer : 0;
        return answerA - answerB;
      });
    }

    setSubRemediations(tmpSubRemediations || []); 
  }, [assessmentQuestionsStatus, question.id]);

  const resetSelectedCtrlAns = () => {
    if (!assessmentQuestionsStatus || !question.id || !assessmentQuestionsStatus[question.id]) {
      setSelectedCtrlAns({});
      return;
    }

    const { assessorQuestionAnswers } = assessmentQuestionsStatus[question.id];
    if (!assessorQuestionAnswers || assessorQuestionAnswers.length === 0) {
      setSelectedCtrlAns({});
      return;
    }
  
    setSelectedCtrlAns([...assessorQuestionAnswers].reduce((acc: { [key: string]: PIDetails }, curr) => {
      if (acc[curr.answer] !== undefined) return acc;
      acc[curr.answer] = {
        compensatingOrNotApplicable: curr.compensatingOrNotApplicable,
        isImplemented: curr.isImplemented,
        comment: curr.comment,
        id: curr.id,
        answer: curr.answer,
      };
      return acc;
    }, {}));
  };

  const handleAssessorFindingModalClose = () => {
    setErrorMessage('');
    setAssessorAnswer((assessmentQuestionsStatus && assessmentQuestionsStatus[question.id] && assessmentQuestionsStatus[question.id]?.answer) || '');
    setAssessorFinding((assessmentQuestionsStatus && assessmentQuestionsStatus[question.id] && assessmentQuestionsStatus[question.id]?.assessorFinding) || '');
    setAssessorFindingModalOpen(false);
    resetSelectedCtrlAns();
  };
  const handleAssessorFindingsClose = () => {
    setErrorMessage('');
    setAssessorAnswer((assessmentQuestionsStatus && assessmentQuestionsStatus[question.id] && assessmentQuestionsStatus[question.id]?.answer) || '');
    setAssessorFinding((assessmentQuestionsStatus && assessmentQuestionsStatus[question.id] && assessmentQuestionsStatus[question.id]?.assessorFinding) || '');
    setIsAssessmentFindingsOpen(false);
  };
  const [remediationModal, setRemediationModalOpen] = useState(false);
  const handleRemediationModalOpen = () => setRemediationModalOpen(true);
  const handleRemediationModalClose = () => {
    setSubRemediationFromAssessorQuestionAnswers();
    setStepIndex(0);
    setSubRemNVFiles({});
    setRemediationModalOpen(false);
  };
  const [attachmentModal, setAttachmentModalOpen] = useState(false);
  const [qIdsFetched, setQIdsFetched] = useState<{
    [key: number]: boolean;
  }>({});
  const handleAttachmentModalOpen = () => {
    setNFiles(files);
    setAttachmentModalOpen(true);
  };
  const handleAttachmentModalClose = () => setAttachmentModalOpen(false);
  const [newSurveyId, setnewSurveyId] = useState<number | undefined>(undefined);
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);
  //const handleUploadDocumentOpen = () => setUploadDocumentOpen(true);
  const handleUploadDocumentClose = () => setUploadDocumentOpen(false);
  const [certUploadedOpen, setCertUploadedOpen] = useState(false);
  const handleCertUploadedOpen = () => setCertUploadedOpen(true);
  const handleCertUploadedClose = () => setCertUploadedOpen(false);
  const handleVendorStatusChange = (newVendorStatus: string) => {
    setErrorMessage('');
    setVendorStatus(newVendorStatus);
  };
  const [showSnackBar, setShowSnackBar] = useState(false);

  const handleSetSubRemNVFile = useCallback((tfiles: { [key: string]: FileUpload[] }) => {
    setSubRemNVFiles({ ...subRemNVFiles, ...tfiles });
    setSubRemVFiles({ ...subRemVFiles, ...tfiles });
  }, [subRemNVFiles]);

  const handleSetNVFile = (tfiles: FileUpload[]) => {
    const newFiles = tfiles.flat(1);
    setNvFiles(newFiles);
  };

  const handleSetNFiles = (tfiles: FileUpload[]) => {
    const newFiles = tfiles.flat(1);
    setNFiles(newFiles);
  };

  useEffect(() => {
    if (
      !allComments ||
      !assessmentQuestionsStatus ||
      !assessmentQuestionsStatus[question.id] ||
      assessmentQuestionsStatus[question.id].comments?.length === 0 ||
      (comments && comments.length === assessmentQuestionsStatus[question.id].comments?.length)
    )
      return;
    const newComments: Comment[] = [];
    const commentsToFetch: number[] = [];
    assessmentQuestionsStatus[question.id].comments?.forEach((comment) => {
      const commentId = typeof comment === 'number' ? comment : comment.id;
      if (!commentId) return;
      if (allComments[commentId]) {
        newComments.push(allComments[commentId]);
      } else {
        commentsToFetch.push(commentId);
      }
    });
    setComments(newComments);
    if (commentsToFetch.length) {
      getMultipleComments(commentsToFetch).then((fetchedComments) => dispatch(setStoreComments(fetchedComments)));
    }
  }, [allComments, assessmentQuestionsStatus]);

  useEffect(() => {
    if (!questionStatus) return;
    const curQStatus = questionStatus[question.id];
    if (!curQStatus || !curQStatus.questionAnswer) return;

    const handleGetAuditLogByQuestionAnswerId = async (qId: any) => {
      const logs = await getAuditLogByQuestionAnswerId(qId);
      dispatch(setQuestionAuditLogFetched(question.id));
      dispatch(setAuditLogs(logs));
    };
    if (!curQStatus.auditLogsFetched || !auditLogKill && !qIdsFetched[question.id]) {
      setQIdsFetched({ ...qIdsFetched, [question.id]: true });
      setAuditLogKill(true);
      handleGetAuditLogByQuestionAnswerId(curQStatus?.questionAnswer?.id);        
    } else {
      if (!auditLogs) return;
      const newAuditLogs: AuditLog[] = lastUpdated ? [...lastUpdated] : [];

      Object.keys(auditLogs).forEach((log) => {
        if (
          auditLogs[log].questionAnswer === curQStatus.questionAnswer?.id &&
          (!lastUpdated || lastUpdated.findIndex((lu) => lu.id === auditLogs[log].id) === -1)
        )
          newAuditLogs.push(auditLogs[log]);
      });

      setLastUpdated(
        newAuditLogs.sort((a, b) => {
          if (a.createdAt! < b.createdAt!) return 1;
          if (a.createdAt! > b.createdAt!) return -1;
          return 0;
        }),
      );
    }
  }, [auditLogs, questionStatus]);

  const primeSelectedCtrlAns = useCallback(() => {
    setSelectedCtrlAns(
      question.answers.filter(it => !['Fully Implemented', 'Not Implemented', 'Not Applicable'].includes(it.text || '')).reduce((acc: { [key: string]: PIDetails }, curr) => {
        acc[curr.id] = {
          compensatingOrNotApplicable: '',
          isImplemented: false,
          comment: '',
          answer: curr.id,
        };
        return acc;
      }, {}),
    );
  }, [question.answers]);

  useEffect(() => {
    if (
      !auditLogs ||
      !assessmentQuestionsStatus ||
      !assessmentQuestionsStatus[question.id] ||
      assessmentQuestionsStatus[question.id].auditLogEntries?.length === 0 ||
      (lastUpdated && assessmentQuestionsStatus[question.id].auditLogEntries?.length === lastUpdated.length)
    )
      return;
    const newAuditLogs: AuditLog[] = lastUpdated ? [...lastUpdated] : [];

    assessmentQuestionsStatus[question.id].auditLogEntries?.forEach((log) => {
      const logId = typeof log === 'number' ? log : log.id;
      if (!logId) return;
      if (auditLogs[logId] && (!lastUpdated || lastUpdated.findIndex((lu) => lu.id === logId) === -1))
        newAuditLogs.push(auditLogs[logId]);
    });
    setLastUpdated(
      newAuditLogs.sort((a, b) => {
        if (a.createdAt! < b.createdAt!) return 1;
        if (a.createdAt! > b.createdAt!) return -1;
        return 0;
      }),
    );
  }, [auditLogs, assessmentQuestionsStatus]);

  useEffect(() => {
    if (!questionStatus) return;
    const curQStatus = questionStatus[question.id];

    const evidenceids: number[] = [];
    const existingEvidence = Object.keys(evidenceById);
    if (curQStatus && curQStatus.questionAnswer && curQStatus.questionAnswer.attachments) {
      curQStatus.questionAnswer.attachments.forEach((ev) => {
        if (existingEvidence.indexOf(ev.toString()) === -1) evidenceids.push(ev);
      });
    }

    if (
      assessmentQuestionsStatus &&
      assessmentQuestionsStatus[question.id] &&
      assessmentQuestionsStatus[question.id].attachments
    ) {
      assessmentQuestionsStatus[question.id].attachments?.forEach((ev) => {
        if (existingEvidence.indexOf(ev.toString()) === -1) evidenceids.push(ev);
      });
    }
    
    if (evidenceids.length > 0) {
      getManyEvidence(evidenceids).then((nev) => {
        let shouldSetEvidence = false;
        nev.forEach(e => {
          if (evidenceById[e.id!] === undefined) shouldSetEvidence = true;
        });
        if (shouldSetEvidence) dispatch(setEvidence(nev));
      });
    }

    if (
      curQStatus &&
      curQStatus.questionAnswer &&
      curQStatus.questionAnswer.attachments &&
      files.length !== curQStatus.questionAnswer.attachments.length
    ) {
      const newFileList: FileUpload[] = [];
      curQStatus.questionAnswer.attachments.forEach((ev) => {
        if (evidenceById[ev]) {
          // if user's content owner and evidence is NOT public, skip it
          if (user?.type === 'content_owner' && !evidenceById[ev].isPublic) {
            return;
          }

          newFileList.push({
            id: evidenceById[ev].id,
            file: evidenceById[ev].file.document,
            date: evidenceById[ev].file.createdAt,
            user: evidenceById[ev].createdBy as User,
            isPublic: evidenceById[ev].isPublic,
          });
        }
      });
      setFiles(newFileList);
    }
    if (
      !assessmentQuestionsStatus ||
      !assessmentQuestionsStatus[question.id] ||
      !assessmentQuestionsStatus[question.id].attachments ||
      assessmentQuestionsStatus[question.id].attachments?.length === 0
    )
      return;
    if (vfiles.length !== assessmentQuestionsStatus[question.id].attachments?.length) {
      const newvFileList: FileUpload[] = [];
      assessmentQuestionsStatus[question.id].attachments?.forEach((ev) => {
        if (evidenceById[ev]) {
          newvFileList.push({
            id: evidenceById[ev].id,
            file: evidenceById[ev].file.document,
            date: evidenceById[ev].file.createdAt,
            user: evidenceById[ev].createdBy as User,
            isPublic: evidenceById[ev].isPublic,
          });
        }
      });
      setvFiles(newvFileList);
    }

    if (assessmentQuestionsStatus[question.id].remediationQuestionAnswers) {
      const subRemQAs = assessmentQuestionsStatus[question.id].remediationQuestionAnswers;
      const subRemediationFiles: { [key: string]: FileUpload[] } = {};
      if (!subRemQAs) return;
      subRemQAs.forEach((subRem) => {
        if (!subRem.attachments) return;
        const subRemFiles: FileUpload[] = [];
        subRem.attachments.forEach((ev: number) => {
          if (evidenceById[ev]) {
            subRemFiles.push({
              id: evidenceById[ev].id,
              file: evidenceById[ev].file.document,
              date: evidenceById[ev].file.createdAt,
              user: evidenceById[ev].createdBy as User,
              isPublic: evidenceById[ev].isPublic,
            });
          }
        });
        subRemediationFiles[subRem.answer.toString()] = subRemFiles;
      });
      setSubRemVFiles(subRemediationFiles);
    }

  }, [questionStatus, evidenceById, assessmentQuestionsStatus]);

  const debounceNextQuestion = React.useRef(
    debounce(() => {
      dispatch(nextQuestion());
    }, 25),
  ).current;

  useEffect(() => {
    if (!questionItem) return;
    setExpanded(questionItem.id === question.id);
  }, [questionItem]);

  useEffect(() => {
    if (!surveys || !survey || (survey.questionnaire as Questionnaire).type === QuestionnaireTypes.MPA_BEST_PRACTICE) return;
    let surveyExists = undefined;

    if (survey.site)
      surveyExists = surveys.find(
        (sur) => (sur.questionnaire as Questionnaire).type === QuestionnaireTypes.MPA_BEST_PRACTICE && sur.site === survey.site,
      );

    if (survey.application)
      surveyExists = surveys.find(
        (sur) => (sur.questionnaire as Questionnaire).type === QuestionnaireTypes.MPA_BEST_PRACTICE && sur.application === survey.application,
      );

    if (surveyExists) setnewSurveyId(surveyExists.id);
  }, [surveys]);

  useEffect(() => {
    if (!bpStatus || !waitForNext || !questionStatus) return;
    if (Object.keys(questionStatus).find(q =>questionStatus[q].questionAnswer && !questionStatus[q].questionAnswer?.id)) return;

    setWaitForNext(false);

    if (!bestPractice || !bpStatus[bestPractice.id]) {
      debounceNextQuestion();
      return;
    }

    if (bpStatus[bestPractice.id].firstUnanswered && bpStatus[bestPractice.id].lastQuestion === question.id) {
      dispatch(setQuestion(bpStatus[bestPractice.id].firstUnanswered!));
      return;
    }
    if (bpStatus[bestPractice.id].visibleQuestionsCount === bpStatus[bestPractice.id].questionsAnsweredCount) {
      let surveyComplete = true;
      Object.keys(bpStatus).forEach((bpid) => {
        if (
          bpStatus[bpid].visibleQuestionsCount !== bpStatus[bpid].questionsAnsweredCount &&
          bpStatus[bpid].status !== 'hide'
        )
          surveyComplete = false;
      });
      if (!surveyComplete) {
        handleBPCompleteOpen();
        return;
      }
    }
    debounceNextQuestion();
  }, [bpStatus]);

  useEffect(() => {
    if (!question) return;
    setCurrentQuestion(props.question);

    if (
      (certControlsById && !companyCerts) ||
      !question.excludedByCertifications ||
      question.excludedByCertifications.length === 0
    )
      return;

    let certifications: CertificationControl[] = [];
    question.excludedByCertifications.forEach((cert) => {
      if (!cert) return;
      if (typeof cert === 'number' && certControlsById) {
        const ccontrol = certControlsById[cert];
        certifications.push(ccontrol);
      }
      if (typeof cert !== 'number') certifications.push(cert);
    });
    if (certifications.length > 0) setCurrentQuestion({ ...props.question, excludedByCertifications: certifications });
  }, [props.question, certControlsById]);

  useEffect(() => {
    if (!companyCerts || companyCerts.length === 0) {
      setCertificationControl(undefined);
      return;
    }
    question.excludedByCertifications.forEach((certControl) => {
      const ncertControl: CertificationControl | boolean =
        typeof certControl === 'number' && certControlsById
          ? certControlsById[certControl]
          : typeof certControl !== 'number'
            ? certControl
            : false;
      if (ncertControl && companyCerts?.find((cert) => cert.certification.id === ncertControl.certification.id))
        setCertificationControl(ncertControl);
    });
  }, [companyCerts]);

  const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;

  const setQuestionHandler = useCallback(() => {
    if (!questionItem) {
      setExpanded(!expanded);
      return;
    }
    dispatch(setQuestion(question));
    handleAssessorFindingsClose();
  }, [questionItem, expanded, question, handleAssessorFindingsClose]);

  const createNewAuditLog = async (
    method: 'delete' | 'post' | 'patch',
    event: string,
    description: string,
    qaResult?: QuestionAnswer,
  ) => {
    if (!user || !survey) return;
    const questionAnswerExists =
      qaResult ||
      (questionStatus && questionStatus[question.id] && questionStatus[question.id].questionAnswer !== undefined);
    const newAuditLog = await createAuditLog({
      user,
      method,
      event,
      description,
      directLink:
        window.location.origin +
        window.location.pathname +
        (window.location.pathname[window.location.pathname.length - 1] !== '/' ? '/' : '') +
        '?question=' +
        question?.id,
      assessorAnswer:
        assessmentQuestionsStatus && assessmentQuestionsStatus[question.id]
          ? assessmentQuestionsStatus[question.id].id
          : undefined,
      questionAnswer: questionAnswerExists
        ? qaResult
          ? qaResult.id
          : questionStatus[question.id].questionAnswer?.id
        : undefined,
      assessment: assessment ? assessment.id : undefined,
      assessmentSurvey: assessment
        ? (assessment.surveys as AssessmentSurvey[]).find((asssur) =>
          typeof asssur.survey === 'number' ? asssur.survey === survey?.id : asssur.survey.id === survey?.id,
        )?.id
        : undefined,
      companies: assessment
        ? [
          typeof assessment.assessor === 'number' ? assessment.assessor : assessment.assessor.id!,
          typeof assessment.company === 'number' ? assessment.company : assessment.company.id!,
        ]
        : [typeof survey.company === 'number' ? survey.company : survey.company.id!],
    });

    return newAuditLog;
  };

  const handleUpdateAssessmentQuestion = async (prop: {
    status?: string;
    comment?: Comment;
    auditLog?: AuditLog;
    contentOwnerPriority?: number[];
    attachments?: number[];
    savePIQA?: boolean;
    savedSubRemFiles?: { [key: string]: FileUpload[] };
    subRemediations?: SubRemediation[];
    questionCtrlAns?: { [key: number]: PIDetails };
  }) => {
    if (!assessmentQuestionsStatus) return;
    if (!prop.status) prop.status = curQuestionStatus;
    if (assessorAnswer && prop.status !== 'priority')
      prop.status =
        ['Fully Implemented', 'Not Applicable'].indexOf(assessorAnswer) !== -1 ? 'assessorApproved' : 'remediation';
    if (prop.status === 'priority' && ['Fully Implemented', 'Not Applicable'].indexOf(assessorAnswer) !== -1)
      prop.status = 'assessorApproved';
    if (vendorStatus === REMEDIATION_OPTIONS.remediated) prop.status = 'submitted';
    if (user?.type === 'content_owner') {
      const companyId = typeof user?.companies[0] === 'number' ? user.companies[0] : user?.companies[0].id;
      if (
        companyId &&
        prop.status === 'remediation' &&
        prop.contentOwnerPriority &&
        prop.contentOwnerPriority.indexOf(companyId) !== -1
      )
        prop.status = 'priority';
      if (
        companyId &&
        prop.status === 'priority' &&
        prop.contentOwnerPriority &&
        prop.contentOwnerPriority.indexOf(companyId) === -1
      )
        prop.status = 'remediation';
    }

    const data = prop.savePIQA ? undefined : await handleSaveAssessorQuestionAnswer(prop.questionCtrlAns, question.id, assessmentQuestionsStatus);
    if (data) dispatch(setAllAssessmentQuestionStatus(data));

    if (prop.subRemediations) {
      // Append the sub remediation files to the sub remediation object
      const subRems = prop.subRemediations.map((subRem) => {
        const subRemFiles = prop.savedSubRemFiles && prop.savedSubRemFiles[subRem.answer as number];
        if (subRemFiles) {
          subRem.attachments = subRemFiles.map((file) => file.id as number);
        }
        return subRem;
      });
      prop.subRemediations = subRems;
    }

    const createdSubRemediations = prop.subRemediations ? await handleSaveSubRemediations(prop.subRemediations) : undefined;
    dispatch(
      setAssessmentQuestionStatus({
        question,
        status: prop.status,
        comment: prop.comment,
        auditLog: prop.auditLog,
        assessorFinding: assessorFinding,
        assessorAnswer: assessorAnswer,
        remediationQuestionAnswers: createdSubRemediations
          ? createdSubRemediations
          : prop.subRemediations?.reduce((acc: any[], curr) => {
            if (curr.answer === undefined) return acc;
            acc.push({
              answer: curr.answer,
              assessorAnswer: assessmentQuestionsStatus && assessmentQuestionsStatus[question.id]?.id,
              remediationComment: curr.remediationComment,
              remediationDate: curr.remediationDate,
              remediationPlan: curr.remediationPlan,
              attachments: [],
            });
            return acc;
          }, []),
        vendorStatus,
        remediationComment,
        assessorQuestionAnswers: 
        (data && data[question.id]) ? (data[question.id]?.assessorQuestionAnswers ?? []).map((value) => (
          {
            id: value.id,
            answer: value.answer,
            comment: value.comment,
            isImplemented: value.isImplemented,
            compensatingOrNotApplicable: value.compensatingOrNotApplicable,
          }
        )) as any[] :
          Object.entries(prop?.questionCtrlAns ?? {}).map(([key, value]) => (
            {
              answer: parseInt(key),
              comment: value.comment || '',
              isImplemented: value.isImplemented || false,
              compensatingOrNotApplicable: value.compensatingOrNotApplicable || '',
            }
          )) as any[],
        contentOwnerPriority: prop.contentOwnerPriority,
        remediationDate: remediationDate ? moment(remediationDate).utc().format('YYYY-MM-DDTHH:mm:ssZZ') : undefined,
        attachments: prop.attachments,
        assessmentSurvey: assessment
          ? (assessment.surveys as AssessmentSurvey[]).find((asssur) =>
            typeof asssur.survey === 'number' ? asssur.survey === survey?.id : asssur.survey.id === survey?.id,
          )?.id
          : undefined,
      }),
    );
  };

  const [baseSelectedCtrlAns, setBaseSelectedCtrlAns] = useState<{ [key: string]: PIDetails }>({});

  const handleUpdateAssessorAnswer = async (savePIQA?: boolean) => {
    if (!assessmentQuestionsStatus || !user) return;
    if (!assessorAnswer || assessorFinding.length === 0) {
      setErrorMessage('Assessor Findings are required');
      return;
    }
    setErrorMessage('');
    const curQuestion = assessmentQuestionsStatus[question.id];
    const method = !curQuestion ? 'post' : 'patch';
    let description = 'Assessor Answer and Finding added';
    if (method === 'patch') {
      if (assessorAnswer !== curQuestion.answer && assessorFinding !== curQuestion.assessorFinding) {
        description = 'Assessor Answer and Finding updated';
      } else {
        description = assessorAnswer !== curQuestion.answer ? 'Assessor Answer updated' : 'Assessor Finding updated';
      }
    }
    const auditLog = await createNewAuditLog(method, 'Assessor Answer', description);
    handleUpdateAssessmentQuestion({ auditLog, questionCtrlAns: baseSelectedCtrlAns, savePIQA });
    handleAssessorFindingModalClose();
    handleAssessorFindingsClose();
  };

  const handleUpdateState = (qAnswer: QuestionAnswer) => {
    dispatch(setQuestionAnswer(question.id, qAnswer));
  };

  const watchedValues = watch(['compensatingControlAnswers', 'nonApplicableAnswers', 'nonImplementedAnswers']);
  useEffect(() => {
    if (!questionStatus || !questionStatus[question.id]) return;
    const curQStatus = questionStatus[question.id];
    if (curQStatus.status === 'hide' && !props.relavantQuestionStatus?.questionAnswer) setCurStatus(curQStatus.status);

    if (!shouldUseAssessmentColors(assessmentStatus)) {
      setCurStatus(curQStatus.status);
      if (curQStatus.status === 'incomplete') setColor('info');
      if (curQStatus.status === 'complete') setColor('success');
      if (curQStatus.status === 'remediation') setColor('error');
      if (curQStatus.status === 'submitted') setColor('warning');
      if (curQStatus.status === 'cert') setColor('primary');
      if (curQStatus.status === 'priority') setColor('secondary');
      if (curQStatus.status === 'non-compliant') setColor('nonCompliant');
    }

    if (curQStatus.satisfiedInclusions) {
      const newSatInc = curQStatus.satisfiedInclusions?.filter((data, idx) => idx === 0);
      const newInc = newSatInc ? question.inclusions.find((inc) => inc.id === newSatInc[0]) : undefined;
      setInclusion(newInc ? newInc : undefined);
    } else {
      setInclusion(undefined);
    }

    if (!curQStatus.questionAnswer || (curQuestionAnswer && isEqual(curQuestionAnswer, curQStatus.questionAnswer)))
      return;
    setCurQuestionAnswer(curQStatus.questionAnswer);
    // Set values on question selection change
    if (curQStatus.questionAnswer.compensatingControlAnswers) {
      const ccAnswer = curQStatus.questionAnswer.compensatingControlAnswers;
      if (question.type === 'multi_select') setValue('compensatingControlAnswers', ccAnswer);
      if (question.type === 'single_select') setValue('compensatingControlAnswers', ccAnswer[0]);
    }
    if (curQStatus.questionAnswer.nonApplicableAnswers) {
      const naAnswer = curQStatus.questionAnswer.nonApplicableAnswers;
      if (question.type === 'multi_select') setValue('nonApplicableAnswers', naAnswer);
      if (question.type === 'single_select') setValue('nonApplicableAnswers', naAnswer[0]);
    }
    if (curQStatus.questionAnswer.nonImplementedAnswers) {
      const niAnswer = curQStatus.questionAnswer.nonImplementedAnswers;
      if (question.type === 'multi_select') setValue('nonImplementedAnswers', niAnswer);
      if (question.type === 'single_select') setValue('nonImplementedAnswers', niAnswer[0]);
    }
    if (curQStatus.questionAnswer?.answer) {
      const answer = curQStatus.questionAnswer.answer;
      if (question.type === 'single_select') setValue('answer', answer[0]);
      if (question.type === 'multi_select') setValue('answer', answer);
    }

    if (curQStatus.questionAnswer?.text) setValue('text', curQStatus.questionAnswer.text);
    if (curQStatus.questionAnswer?.comment) setValue('comment', curQStatus.questionAnswer.comment);
    if (!preview && user && user.type !== 'vendor_admin' && questionnaire && questionnaire.type !== QuestionnaireTypes.MPA_BEST_PRACTICE)
      return;
    if (!curQStatus.questionAnswer || !curQStatus.questionAnswer.status) return;
    let status = '';
    try {
      status = JSON.parse(curQStatus.questionAnswer.status).status;
    } catch (e) {
      status = curQStatus.questionAnswer.status;
    }
    if (status !== curQStatus.status && certControlsById) {
      status = curQStatus.status;
      if (preview || (assessmentStatus && assessmentStatus !== 'Pre-Assessment')) {
        handleUpdateState({ ...curQStatus.questionAnswer, status });
      } else {
        let qAnswer = {
          compensatingControlAnswers: watchedValues[0] || [],
          nonApplicableAnswers: watchedValues[1] || [],
          nonImplementedAnswers: watchedValues[2] || [],
        } as QuestionAnswer;

        const req = {
          ...{ ...curQStatus.questionAnswer }, // Shallow clone
          ...qAnswer,
          status,
        };

        updateQuestionAnswer(req).then((qaResult: QuestionAnswer) => {
          handleUpdateState(qaResult);
          setCurQuestionAnswer(qaResult);
        });
      }
    }
  }, [questionStatus]);

  useEffect(() => {
    if (!assessmentQuestionsStatus || !assessmentQuestionsStatus[question.id]) return;
    const curQStatus = assessmentQuestionsStatus[question.id];
    const currentQuestionStatus = questionStatus[question.id];
    setIsValidCORemPlan(!(assessmentQuestionsStatus[question.id]?.vendorStatus === REMEDIATION_OPTIONS.remediated));
    if (curQStatus.status) {
      setCurStatus(currentQuestionStatus.status === 'hide' ? currentQuestionStatus.status : curQStatus.status);
      if (curQStatus.status === 'remediation') {
        if (!curQStatus.vendorStatus) {
          setColor('error');
        } else if (curQStatus.vendorStatus === 'remediated') {
          setColor('primary');
        } else {
          setColor('nonCompliant');
        }
      }

      if (curQStatus.status === 'priority') setColor('secondary');
      if (curQStatus.status === 'assessorApproved') setColor('success');
    }

    if (curQStatus.assessorFinding) setAssessorFinding(curQStatus.assessorFinding);
    if (curQStatus.answer) setAssessorAnswer(curQStatus.answer);
    if (curQStatus.vendorStatus) setVendorStatus(curQStatus.vendorStatus);
    if (curQStatus.remediationComment) setRemediationComment(curQStatus.remediationComment);
    if (curQStatus.remediationDate) setRemediationDate(moment(curQStatus.remediationDate).format('MM/DD/yyyy'));
  }, [assessmentQuestionsStatus]);

  const submitAnswer = async (values: FieldValues) => {
    if (!questionnaire || !survey) return;

    setWaitForNext(true);
    let qAnswer = {
      question: question.id,
      answer:
        typeof values.answer === 'string'
          ? [parseInt(values.answer)]
          : typeof values.answer === 'number'
            ? [values.answer]
            : values.answer,
      compensatingControlAnswers:
        typeof values.compensatingControlAnswers === 'string'
          ? [parseInt(values.compensatingControlAnswers)]
          : typeof values.compensatingControlAnswers === 'number'
            ? [values.compensatingControlAnswers]
            : values.compensatingControlAnswers,
      nonApplicableAnswers:
        typeof values.nonApplicableAnswers === 'string'
          ? [parseInt(values.nonApplicableAnswers)]
          : typeof values.nonApplicableAnswers === 'number'
            ? [values.nonApplicableAnswers]
            : values.nonApplicableAnswers,
      nonImplementedAnswers:
        typeof values.nonImplementedAnswers === 'string'
          ? [parseInt(values.nonImplementedAnswers)]
          : typeof values.nonImplementedAnswers === 'number'
            ? [values.nonImplementedAnswers]
            : values.nonImplementedAnswers,
      comment: values.comment ? values.comment : '',
      text: values.text ? values.text : '',
      questionnaire: questionnaire.id,
      survey: survey.id,
    } as QuestionAnswer;

    if (qAnswer.comment === '') {
      qAnswer.comment = null;
    }
    if (curQuestionAnswer) qAnswer = { ...curQuestionAnswer, ...qAnswer };
    if (
      preview ||
      (assessmentStatus && assessmentStatus !== 'Pre-Assessment') ||
      (curQuestionAnswer && isEqual(curQuestionAnswer, qAnswer))
    ) {
      handleUpdateState(qAnswer);
      return;
    }
    if (!survey || readOnly || !user || (user.type !== 'vendor_admin' && questionnaire?.type === QuestionnaireTypes.MPA_BEST_PRACTICE))
      return;
    const newLastUpdated: AuditLog[] = lastUpdated ? [...lastUpdated] : [];
    if (questionStatus[question.id].questionAnswer) {
      let status = '';
      if ((questionStatus[question.id].questionAnswer as QuestionAnswer).status) {
        try {
          status = JSON.parse((questionStatus[question.id].questionAnswer as QuestionAnswer).status as string);
        } catch (e) {
          status = (questionStatus[question.id].questionAnswer as QuestionAnswer).status as string;
        }
      }
      createNewAuditLog('patch', 'Answer', 'Update Question Answer').then((log) => {
        if (!log) return;
        dispatch(setAuditLogs([log]));
        newLastUpdated.unshift(log);
        setLastUpdated(newLastUpdated);
      });

      const req = {
        ...{ ...questionStatus[question.id].questionAnswer }, // Shallow clone
        ...qAnswer,
        status,
      };

      updateQuestionAnswer(req).then(
        (qaResult: QuestionAnswer) => {
          handleUpdateState(qaResult);
          setCurQuestionAnswer(qaResult);
        },
      );
    } else {
      createQuestionAnswer({ ...qAnswer }).then((qaResult: QuestionAnswer) => {
        setCurQuestionAnswer(qaResult);
        createNewAuditLog('post', 'Answer', 'Create Question Answer', qaResult).then((log) => {
          if (!log) return;
          dispatch(setAuditLogs([log]));
          newLastUpdated.unshift(log);
          setLastUpdated(newLastUpdated);
        });
        handleUpdateState(qaResult);
      });
    }
  };

  const uploadAttachments = async () => {
    if (!files || !user || user.type !== 'vendor_admin' || !questionStatus || !survey) return;
    handleAttachmentModalClose();

    const newLastUpdated: AuditLog[] = lastUpdated ? [...lastUpdated] : [];
    const onlyNewFiles = nfiles.filter((file) => typeof file.file !== 'string');
    const existingFiles = nfiles.filter((file) => typeof file.file === 'string');
    
    let evidence = await uploadMultipleAttachments(
      onlyNewFiles,
      user,
    );
    const updatedEvidences = await updateEvidences(existingFiles);
    dispatch(setEvidence([...updatedEvidences, ...evidence]));

    const mapEvidence: FileUpload[] = evidence.map((ev) => {
      return {
        id: ev.id,
        file: ev.file.document,
        date: ev.file.createdAt,
        user: ev.createdBy as User,
        isPublic: ev.isPublic,
      };
    });
    const newEvidenceList = existingFiles.length > 0 ? [...existingFiles, ...mapEvidence] : [...mapEvidence];
    files.forEach(f => {
      if (!f.id || newEvidenceList.find(nel => nel.id === f.id)) return;
      deleteEvidence(f.id);
    });

    setFiles(newEvidenceList);
    setNFiles([]);
    const auditLog = await createNewAuditLog('post', 'Evidence', 'Add New Evidence');
    if (auditLog) {
      dispatch(setAuditLogs([auditLog]));
      newLastUpdated.unshift(auditLog);
      setLastUpdated(newLastUpdated);
    }
    if (questionStatus[question.id].questionAnswer) {
      const attachments = newEvidenceList.map((ev) => ev.id!);
      updateQuestionAnswer({ ...questionStatus[question.id].questionAnswer!, attachments }).then(
        (qaResult: QuestionAnswer) => {
          setCurQuestionAnswer(qaResult);
          handleUpdateState(qaResult);
        },
      );
    } else {
      const values = getValues();
      createQuestionAnswer({
        question: question.id!,
        attachments: newEvidenceList.map((ev) => ev.id!),
        questionnaire: questionnaire!.id!,
        status: 'incomplete',
        survey: survey.id,
        comment: values.comment ? values.comment : '',
        answer:
          typeof values.answer === 'string'
            ? [parseInt(values.answer)]
            : typeof values.answer === 'number'
              ? [values.answer]
              : values.answer,
        compensatingControlAnswers:
          typeof values.compensatingControlAnswers === 'string'
            ? [parseInt(values.compensatingControlAnswers)]
            : typeof values.compensatingControlAnswers === 'number'
              ? [values.compensatingControlAnswers]
              : values.compensatingControlAnswers,
        nonApplicableAnswers:
              typeof values.nonApplicableAnswers === 'string'
                ? [parseInt(values.nonApplicableAnswers)]
                : typeof values.nonApplicableAnswers === 'number'
                  ? [values.nonApplicableAnswers]
                  : values.nonApplicableAnswers,
        nonImplementedAnswers:
              typeof values.nonImplementedAnswers === 'string'
                ? [parseInt(values.nonImplementedAnswers)]
                : typeof values.nonImplementedAnswers === 'number'
                  ? [values.nonImplementedAnswers]
                  : values.nonImplementedAnswers,
        text: values.text ? values.text : '',
      }).then((qaResult: QuestionAnswer) => {
        setCurQuestionAnswer(qaResult);
        createNewAuditLog('post', 'Answer', 'Create Question Answer', qaResult).then((log) => {
          if (!log) return;
          dispatch(setAuditLogs([log]));
          newLastUpdated.unshift(log);
          setLastUpdated(newLastUpdated);
        });
        handleUpdateState(qaResult);
      });
    }
  };

  const postComment = async (text: string, attachments: number[]) => {
    if (!assessment || !user || !assessmentQuestionsStatus) return;
    const comment = await createComment({ user, text, attachments });
    const auditLog = await createNewAuditLog('post', 'Comment', 'Add New Comment');
    handleUpdateAssessmentQuestion({ comment, auditLog });
  };

  const companyCallback = (nCompany: Company) => {
    if (!survey || !nCompany.companyCertifications) return;
    const approvedCerts = nCompany.companyCertifications.filter((cert) => cert.adminApproved && (!blueBadge || moment(cert.createdAt).unix() < moment(blueBadge.createdAt).unix()));
    let newCompanyCerts = !survey.application && !survey.site ? approvedCerts : [];
    if (survey.application && survey.version && applicationVersions.length && nCompany.companyApplications) {
      const companyApplication = nCompany.companyApplications.find((CA) => CA.application.id === survey.application);
      if (!companyApplication) return;
      approvedCerts.forEach((ccert) => {
        if (
          ccert.applicationVersions?.find((c) =>
            applicationVersions.find((a) => a.id === c && (a.version as Version).value === survey.version),
          )
        )
          newCompanyCerts.push(ccert);
      });
    }
    if (survey.site) {
      approvedCerts.forEach((ccert) => {
        if (ccert.sites.some((app) => app.id === survey.site)) newCompanyCerts.push(ccert);
      });
    }
    dispatch(setCompanyCertifications(newCompanyCerts));
    handleUploadDocumentClose();
    handleCertUploadedOpen();
  };

  const checkOffQuestionnaireLogic = () => {
    if (
      !questionStatus ||
      !questions ||
      Object.keys(questionStatus).length === 0 ||
      !question ||
      !question.id ||
      !questionStatus[question.id] ||
      question.inclusions.length === 0
    )
      return false;
    let offQuestionnaireLogic = 0;
    question.inclusions.forEach((inc) => {
      const fquestion = questions.filter((q) => q.id === (inc.question as Question).id);
      if (fquestion.length === 0) offQuestionnaireLogic++;
    });
    return offQuestionnaireLogic === question.inclusions.length;
  };

  useEffect(() => {
    setSubRemErr('');
  }, [stepIndex]);

  useEffect(() => {
    if (Object.values(subRemNVFiles).every((subRemFile) => subRemFile.length === 0)) {
      setSubRemErr('');
    }
  }, [subRemNVFiles]);

  const handleUpdateRemediation = async () => {
    if (assessmentQuestionsStatus && assessmentQuestionsStatus[question.id].answer === 'Partially Implemented' && subRemediations.length > 0) {
      setSubRemErr('');
      
      if (!assessmentQuestionsStatus || !user) return;

      if (Object.values(subRemNVFiles).some((subRemFile) => subRemFile.some((file) => file.isPublic === ''))) {
        setSubRemErr('All Evidence must have a CO Visibility value');
        return;
      }

      try {
        const auditLog = await createNewAuditLog('patch', 'Remediate', 'Add/Update Remediation');
        // Process files and generate evidence map
        const subRemEvidence = await processFiles(subRemNVFiles, user);
        if (Object.values(subRemEvidence).includes(null)) {
          setSubRemErr('Failed to upload your file');
          return;
        }

        dispatch(setEvidence(Object.values(subRemEvidence).flat() as Evidence[]));

        const evidenceMap: { [key: string]: FileUpload[] } = Object.entries(subRemEvidence).reduce((acc, [key, value]) => {
          if (!value) return acc;
          acc[key] = value.map(ev => ({
            id: ev.id,
            file: ev.file.document,
            date: ev.file.createdAt,
            user: ev.createdBy as User,
            isPublic: ev.isPublic,
          }));
          return acc;
        }, {} as { [key: string]: FileUpload[] });

        setSubRemVFiles(evidenceMap);
        setSubRemNVFiles({});

        const attachments = Object.values(evidenceMap).flat().map((ev) => ev.id as number);
        handleUpdateAssessmentQuestion({ auditLog, attachments, subRemediations, savedSubRemFiles: evidenceMap });
        setStepIndex(0);
        const nextAssessmentQuestion = Object.values(assessmentQuestionsStatus)
          .sort((a, b) => (a.question as number) - (b.question as number))
          .find((value) => {
            return (value.status === 'remediation' && value.vendorStatus !== 'remediated' && value.question > question.id);
          });

        if (nextAssessmentQuestion) {
          const nextRemQuestion = await getQuestion(nextAssessmentQuestion?.question as number);
          dispatch(setNextRemediationQuestion(nextRemQuestion));
        }

        setShowSnackBar(true);
        handleRemediationModalClose();
      } catch (e) {
        console.error(e);
        setErrorMessage('Error updating remediation');
      }
    } else {
      if (nvfiles.some((file) => file.isPublic === '')) {
        setErrorMessage('All Evidence must have a CO Visibility value');
        return;
      }

      if (!remediationDate || !moment(remediationDate).isValid()) {
        setRemediationDateErr('Remediation Date is required');
        return;
      }

      if (!vendorStatus) {
        setErrorMessage('Select a Remediation Plan');
        return;
      }
      if (
        vendorStatus &&
        (!remediationComment || remediationComment.length === 0)
      ) {
        setErrorMessage('Remediation comment is required');
        return;
      }
      if (vendorStatus && vendorStatus === REMEDIATION_OPTIONS.willRemediateLater && !remediationDate) {
        setErrorMessage(`${REMEDIATION_OPTIONS.willRemediateLater} requires a Remediation Date`);
        return;
      }

      setErrorMessage('');

      if (!assessmentQuestionsStatus || !user) return;

      try {
        const auditLog = await createNewAuditLog('patch', 'Remediate', 'Add/Update Remediation');
  
        const onlyNewFiles = nvfiles.filter((file) => typeof file.file !== 'string');
        const existingFiles = nvfiles.filter((file) => typeof file.file === 'string');
        const evidence = await uploadMultipleAttachments(
          onlyNewFiles,
          user,
        );

        dispatch(setEvidence(evidence));
        const mapEvidence: FileUpload[] = evidence.map((ev) => {
          return {
            id: ev.id,
            file: ev.file.document,
            date: ev.file.createdAt,
            user: ev.createdBy as User,
            isPublic: ev.isPublic,
          };
        });
        const newEvidenceList = existingFiles.length > 0 ? [...existingFiles, ...mapEvidence] : [...mapEvidence];
        setvFiles(newEvidenceList);
        setNvFiles([]);
        const attachments = newEvidenceList.map((ev) => ev.id as number);
  
        handleUpdateAssessmentQuestion({ auditLog, attachments });
        const nextAssessmentQuestion = Object.values(assessmentQuestionsStatus)
          .sort((a, b) => (a.question as number) - (b.question as number))
          .find((value) => {
            return (value.status === 'remediation' && value.vendorStatus !== 'remediated' && value.question > question.id);
          });

        if (nextAssessmentQuestion) {
          const nextRemQuestion = await getQuestion(nextAssessmentQuestion?.question as number);
          dispatch(setNextRemediationQuestion(nextRemQuestion));
        }

        setShowSnackBar(true);
        handleRemediationModalClose();
      } catch (e) {
        console.error(e);
        setErrorMessage('Failed to upload your file');
        return;
      }
    }
  };

  const setContentOwnerPriority = async () => {
    if (
      !curQuestionStatus ||
      !assessmentQuestionsStatus ||
      !assessmentQuestionsStatus[question.id] ||
      !user ||
      user.type !== 'content_owner'
    )
      return;

    const assessmentAnswer = assessmentQuestionsStatus[question.id];
    const companyId = typeof user.companies[0] === 'number' ? user.companies[0] : user.companies[0].id;
    if (!companyId) return;
    const coids: number[] = assessmentAnswer.contentOwnerPriority
      ? assessmentAnswer.contentOwnerPriority.map((co) => (typeof co === 'number' ? co : co.id ? co.id : -1))
      : [];
    const contentOwnerPriority =
      coids.indexOf(companyId) === -1 ? coids.concat(companyId) : coids.filter((id) => id !== companyId);

    const auditLog =
      coids.indexOf(companyId) === -1
        ? await createNewAuditLog('post', 'Content Owner Prioirty', 'Set Content Owner Priority')
        : await createNewAuditLog('delete', 'Content Owner Prioirty', 'Remove Content Owner Priority');
    handleUpdateAssessmentQuestion({
      auditLog,
      contentOwnerPriority: contentOwnerPriority ? contentOwnerPriority : [],
    });
  };

  const filteredComments = comments?.filter((comment) => {
    const assessmentSurvey = assessment
      ? (assessment.surveys as AssessmentSurvey[]).find((asssur) =>
        typeof asssur.survey === 'number' ? asssur.survey === survey?.id : asssur.survey.id === survey?.id,
      )
      : undefined;

    return (
      user?.type !== 'content_owner' ||
      (assessmentSurvey &&
        assessmentSurvey.reportIssueDate &&
        comment.createdAt &&
        assessmentSurvey.reportIssueDate < comment.createdAt)
    );
  });

  const filteredFiles = () => {
    // if CONTENT OWNER
    if (user?.type === 'content_owner') {
      // if already an Assessment OR status is submittedForAssessment
      if (survey?.status === 'submittedForAssessment' || assessment) {
        return files.filter((file) => file.isPublic);
      }

      // return NOTHING if NOT
      return [];
    }

    return files;
  };

  const handleSetShowPIDetails = useCallback((e: React.MouseEvent | undefined) => {
    if (e) e.preventDefault();
    if (!question.id) return;

    const { assessorQuestionAnswers = [], answer = '' } = assessmentQuestionsStatus && assessmentQuestionsStatus[question.id] ? assessmentQuestionsStatus[question.id] : {};
    if (answer === 'Partially Implemented') {
      if (assessorQuestionAnswers && assessorQuestionAnswers.length > 0) {
        setSelectedCtrlAns(assessorQuestionAnswers.reduce((acc: { [key: string]: PIDetails }, curr) => {
          acc[curr.answer] = {
            compensatingOrNotApplicable: baseSelectedCtrlAns[curr.answer]?.compensatingOrNotApplicable || curr.compensatingOrNotApplicable,
            isImplemented: baseSelectedCtrlAns[curr.answer]?.isImplemented || curr.isImplemented,
            comment: baseSelectedCtrlAns[curr.answer]?.comment || curr.comment,
            id: curr.id,
            answer: curr.answer,
          };
          return acc;
        }, {}));
      } else {
        // Prime each answer with a default value
        primeSelectedCtrlAns();
      }
    } else if (assessorAnswer === 'Partially Implemented' && workflow?.status === 'Assessing') {
      // Do not prime if baseSelectedCtrlAns is already in default state
      const hasAnyValidValue = Object.values(baseSelectedCtrlAns).some((value) => value.isImplemented !== false || value.compensatingOrNotApplicable !== '' || value.comment !== '');
      if (hasAnyValidValue) {
        setShowPIDetails(true);
        return;
      }
      primeSelectedCtrlAns();
    }
    setShowPIDetails(true);
  }, [setShowPIDetails, assessmentQuestionsStatus, question.id, assessorAnswer, workflow?.status, baseSelectedCtrlAns]);

  useEffect(() => {
    if (showPIDetails) {
      handleSetShowPIDetails(undefined);
    }
  }, [showPIDetails]);

  const handleSetAssessorAnswer = useCallback((answer: string) => {
    setShowPIDetails(answer === 'Partially Implemented');
    setAssessorAnswer(answer);
  }, [setAssessorAnswer]);

  useEffect(() => {
    if (!assessmentQuestionsStatus || !question.id || !assessmentQuestionsStatus[question.id]) return;
    
    const { assessorQuestionAnswers, remediationQuestionAnswers } = assessmentQuestionsStatus[question.id];
    if (assessorQuestionAnswers && assessorQuestionAnswers.length > 0) {
      setSelectedCtrlAns([...assessorQuestionAnswers].reduce((acc: { [key: string]: PIDetails }, curr) => {
        acc[curr.answer] = {
          id: curr.id,
          compensatingOrNotApplicable: curr.compensatingOrNotApplicable,
          isImplemented: curr.isImplemented,
          comment: curr.comment,
          answer: curr.answer,
        };
        return acc;
      }, {}));
    }

    if (remediationQuestionAnswers && remediationQuestionAnswers.length > 0) {
      const existingSubRemediations = [...remediationQuestionAnswers].map((remediation) => ({
        id: remediation.id,
        assessorAnswer: remediation.assessorAnswer,
        remediationPlan: remediation.remediationPlan,
        remediationComment: remediation.remediationComment,
        remediationDate: remediation.remediationDate,
        attachments: remediation.attachments,
        answer: remediation.answer,
        text: question.answers.find((answer) => answer.id === remediation.answer)?.text,
      }));
      existingSubRemediations.sort((a, b) => Number(a.answer) - Number(b.answer));
      setSubRemediations(existingSubRemediations);
    } else {
      setSubRemediationFromAssessorQuestionAnswers();
    }
    
  }, [assessmentQuestionsStatus, question.id]);

  useEffect(() => {
    // On change of subRemediations, set parent data if all sub-remediations are valid
    if (!subRemediations || subRemediations.length === 0 || !subRemediations.every(subRem => subRem.remediationPlan && subRem.remediationDate && subRem.remediationComment)) return;
    
    const parentVendorStatus = subRemediations.reduce((acc: string, curr) => {
      if (acc === REMEDIATION_OPTIONS.willNotRemediate) return acc;
      if (curr.remediationPlan === REMEDIATION_OPTIONS.willNotRemediate) {
        return REMEDIATION_OPTIONS.willNotRemediate;
      } else if (curr.remediationPlan === REMEDIATION_OPTIONS.willRemediateLater) {
        return REMEDIATION_OPTIONS.willRemediateLater;
      }
      return acc;
    }, REMEDIATION_OPTIONS.remediated);

    setVendorStatus(parentVendorStatus);
    setRemediationDate(remediationDate || new Date().toISOString());
    setRemediationComment('');
  }, [subRemediations]);


  useEffect(() => {
    setBaseSelectedCtrlAns({ ...JSON.parse(JSON.stringify(selectedCtrlAns)) });
  }, [selectedCtrlAns]);

  useEffect(() => {
    // If the assessorAnswer is not Partially Implemented, clear the selectedCtrlAns all values are default
    if (assessorAnswer !== 'Partially Implemented') {
      const hasDefaulted = Object.values(selectedCtrlAns).every((value) => value.isImplemented === false && value.compensatingOrNotApplicable === '' && value.comment === '');
      if (hasDefaulted) {
        setBaseSelectedCtrlAns({});
      }
    }
  }, [assessorAnswer]);

  const handleStep = useCallback((step: number) => {
    setStepIndex(step);
  }, [setStepIndex]);

  const coPriorityBtnLabel = useMemo(() => {
    const contentOwnerPriority = assessmentQuestionsStatus?.[question.id]?.contentOwnerPriority as number[] | undefined;
    const companyId = user?.companies[0]?.id;

    if (
      curQuestionStatus === 'remediation' ||
      (curQuestionStatus !== 'remediation' &&
      contentOwnerPriority &&
      companyId &&
      !contentOwnerPriority.includes(companyId))
    ) {
      return 'Mark as Priority';
    } else {
      return 'Mark not Priority';
    }
  }, [curQuestionStatus, assessmentQuestionsStatus, user, question.id]);

  return (
    <>
      <AssessorPIDetails
        updateAssessorFindings={(findings : string) => setAssessorFinding(findings)}
        assessorFinding={assessorFinding}
        question={question}
        control={control}
        canEdit={user?.type === 'assessor'}
        show={showPIDetails}
        setShow={setShowPIDetails}
        selectedCtrlAns={baseSelectedCtrlAns}
        setSelectedCtrlAns={setBaseSelectedCtrlAns} 
      />
      <Snackbar
        open={showSnackBar}
        autoHideDuration={4000}
        TransitionComponent={Slide}
        onClose={() => setShowSnackBar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setShowSnackBar(false)} icon={<Done fontSize="inherit" />} severity="success" sx={{ width: '100%' }}>
        Remediation updated
        </Alert>
      </Snackbar>
      {questionStatus &&
        Object.keys(questionStatus).length > 0 &&
        question &&
        question.id &&
        (curQuestionStatus !== 'hide' || (curQuestionStatus === 'hide' && preview && checkOffQuestionnaireLogic())) && (
          <>
            <Card
              sx={{
                mb: 1,
                ml: 0,
                backgroundColor:
                  color === 'info'
                    ? 'white'
                    : getBackgroundColor(theme.palette[color].light, color === 'assessorApproved' ? 0.7 : 0.9),
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ p: 2 }}
                component='form'
                method='post'
                onSubmit={handleSubmit(submitAnswer)}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setQuestionHandler();
                  }}
                >
                  <QuestionOnQuestionnaireHeader
                    questions={questions}
                    question={question}
                    comments={comments}
                    inclusion={inclusion}
                    certificationControl={certificationControl}
                    curQuestionStatus={curQuestionStatus}
                    color={color}
                    onCommentModalOpen={handleCommentModalOpen}
                  />
                </Grid>
                {expanded && (
                  <>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <Typography sx={{ whiteSpace: 'pre-wrap' }}>{question.description}</Typography>
                    </Grid>
                    <QuestionOnQuestionnaireAnswer
                      question={question}
                      questionStatus={questionStatus}
                      curQuestionStatus={curQuestionStatus}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      register={register}
                      readOnly={readOnly}
                      preview={preview}
                      submitted={submitted}
                      color={color}
                    />
                    <AttachmentFormAndComment
                      user={user}
                      question={question}
                      comments={filteredComments}
                      assessment={assessment}
                      assessorFinding={assessorFinding}
                      assessorAnswer={assessorAnswer}
                      files={filteredFiles()}
                      register={register}
                      readOnly={readOnly}
                      preview={preview}
                      submitted={submitted}
                      onAttachmentModalOpen={handleAttachmentModalOpen}
                      onCommentModalOpen={handleCommentModalOpen}
                      onAssessorFindingsModalOpen={handleAssessorFindingModalOpen}
                      surveyComplete={['submittedForAssessment', 'complete'].includes(survey?.status ?? '')}
                    />
                    <Divider sx={{ ml: 2, mt: 2, width: 'calc(100% - 16px)' }} />
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}
                    >
                      {['remediation', 'priority', 'submitted'].indexOf(curQuestionStatus.toLowerCase()) === -1 &&
                        (!readOnly || (readOnly && preview)) && (
                          <Button variant='contained' endIcon={<NavigateNext />} type='submit'>
                            Save and Continue
                          </Button>
                      )}
                      {['remediation', 'priority', 'submitted'].indexOf(curQuestionStatus.toLowerCase()) === -1 &&
                        readOnly && <Box />}
                      {assessmentStatus &&
                        ['Assessing', 'Submitted', 'Remediation'].indexOf(assessmentStatus) !== -1 && (
                          <>
                            {((user && user.type === 'assessor') || preview) &&
                              ['remediation', 'priority'].indexOf(curQuestionStatus) === -1 && !isAssessmentFindingsOpen && (
                                <Button
                                    variant='outlined'
                                    sx={{ backgroundColor: 'white' }}
                                    color='primary'
                                    startIcon={<Check />}
                                    onClick={() => setIsAssessmentFindingsOpen(true)}
                                  >
                                    {assessorAnswer ? 'Update Finding' : 'Assess'}
                                </Button>
                            )}
                            {['remediation', 'priority'].indexOf(curQuestionStatus.toLowerCase()) !== -1 &&
                              assessmentStatus &&
                              user && user.type !== 'assessor' &&
                              assessmentStatus !== 'Remediation' && !isAssessmentFindingsOpen && (
                                <>
                                  <Box>
                                    {((user && user.type === 'assessor') || preview) && (
                                      <Button
                                        variant='outlined'
                                        sx={{ backgroundColor: 'white', mr: 1 }}
                                        color='error'
                                        startIcon={<Warning />}
                                        onClick={() => setIsAssessmentFindingsOpen(true)}
                                      >
                                        Update Remediation
                                      </Button>
                                    )}
                                  </Box>
                                </>
                            )}
                          </>
                      )}
                      {assessmentStatus &&
                        ['Remediation', 'Complete', 'Completed'].indexOf(assessmentStatus) > -1 &&
                        ['remediation', 'priority', 'submitted'].indexOf(curQuestionStatus.toLowerCase()) > -1 && (
                          <Box>
                            {((user && user.type === 'content_owner' && (assessmentStatus === 'Remediation' || isValidCORemPlan)) ||
                              preview) && (
                              <Button
                                variant='outlined'
                                sx={{ backgroundColor: 'white' }}
                                color='secondary'
                                startIcon={<Check />}
                                onClick={() => setContentOwnerPriority()}
                              >
                                {coPriorityBtnLabel}
                              </Button>
                            )}
                            <Button
                              sx={{ ml: 1 }}
                              variant='contained'
                              startIcon={<Check />}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRemediationModalOpen();
                              }}
                            >
                              {user?.type === 'vendor_admin'
                                ? vendorStatus
                                  ? 'Update Remediation'
                                  : 'Remediate'
                                : 'View Remediation'}
                            </Button>
                          </Box>
                      )}
                    </Grid>
                    {assessmentStatus && (user && user.type === 'assessor') && (
                      <Collapse in={isAssessmentFindingsOpen} sx={{ width: '100%', px: 3, py: 1 }}>
                        <Typography variant='h5' fontWeight='bold'>Assessor Finding for {question.title}</Typography>
                        <AssessorFindingsForm
                          handleSetShowPIDetails={handleSetShowPIDetails}
                          user={user}
                          question={question}
                          assessorAnswer={assessorAnswer}
                          assessmentStatus={assessmentStatus}
                          assessorFindings={assessorFinding}
                          onAssessorAnswerChange={(answer) => handleSetAssessorAnswer(answer)}
                          onAssessorFindingsChange={(findings) => setAssessorFinding(findings)}
                        />
                        {errorMessage.length > 0 && (
                          <Typography color='error' sx={{ mt: 2 }}>
                            {errorMessage}
                          </Typography>
                        )}
                        <Box sx={{ display: 'flex', columnGap: 2, mt: 2 }}>
                          <Button
                            variant={
                              user &&
                              user.type === 'assessor' &&
                              assessmentStatus &&
                              ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
                                ? 'outlined'
                                : 'contained'
                            }
                            onClick={handleAssessorFindingsClose}
                          >
                            {user &&
                            user.type === 'assessor' &&
                            assessmentStatus &&
                            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
                              ? 'Cancel'
                              : 'Close'}
                          </Button>
                          {user &&
                            user.type === 'assessor' &&
                            assessmentStatus &&
                            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1 && (
                              <Button variant='contained' color='primary' onClick={() => handleUpdateAssessorAnswer(true)}>
                                Save Findings
                              </Button>
                          )}
                        </Box>
                      </Collapse>
                    )}
                    {lastUpdated &&
                      lastUpdated !== undefined &&
                      lastUpdated.length > 0 &&
                      lastUpdated[0] !== undefined && (
                        <Grid item xs={12} sx={{ textAlign: 'right' }} mt={-1.5}>
                          <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAuditLogModalOpen();
                            }}
                            variant='caption'
                          >
                            Last Updated By {(lastUpdated[0].user as User).firstName}{' '}
                            {(lastUpdated[0].user as User).lastName}{' '}
                            {moment(lastUpdated[0].createdAt).format('MM/DD/YYYY HH:mm')}
                          </Typography>
                        </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Card>
            <StandardDialog
              title={`Comments for Question: ${question.title}`}
              handleClose={handleCommentModalClose}
              isOpen={commentModal}
            >
              <Comments
                comments={filteredComments}
                handleClose={handleCommentModalClose}
                postComment={postComment}
                assessmentSurvey={
                  assessment
                    ? (assessment.surveys as AssessmentSurvey[]).find((asssur) =>
                      typeof asssur.survey === 'number'
                        ? asssur.survey === survey?.id
                        : asssur.survey.id === survey?.id,
                    )
                    : undefined
                }
              />
            </StandardDialog>
            <StandardDialog
              title={`Audit Logs for Question: ${question.title}`}
              handleClose={handleAuditLogModalClose}
              isOpen={auditLogModal}
            >
              <AuditLogModal auditLogs={lastUpdated} handleClose={handleAuditLogModalClose} />
            </StandardDialog>
            <StandardDialog
              title={
                bestPractice && topic && domain
                  ? `${buildBestPracticeTitle({ ...bestPractice, domain, topic })} Complete`
                  : 'Best Practice Complete'
              }
              handleClose={handleBPCompleteClose}
              isOpen={bpComplete}
            >
              <Typography>
                You have completed{' '}
                {bestPractice && topic && domain
                  ? `Best Practice ${buildBestPracticeTitle({ ...bestPractice, domain, topic })}`
                  : 'this Best Practice'}
                , would you like to move on to the next one?
              </Typography>
              <StandardDialogActions>
                <Button variant='outlined' onClick={() => handleBPCompleteClose()}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    debounceNextQuestion();
                    handleBPCompleteClose();
                  }}
                >
                  Continue
                </Button>
              </StandardDialogActions>
            </StandardDialog>
            <StandardDialog
              title={`Upload attachments to question: ${question.title}`}
              handleClose={handleAttachmentModalClose}
              isOpen={attachmentModal}
            >
              {question.attachmentFormLabel && <Typography mb={1}>{question.attachmentFormLabel}</Typography>}
              <FileUploaderComp
                fileList={filteredFiles()}
                callback={handleSetNFiles}
                dontShowUpload={
                  !user || user.type !== 'vendor_admin' || (!!assessmentStatus && assessmentStatus !== 'Pre-Assessment')
                }
                enablePublic={true}
              />
              <StandardDialogActions>
                <Button variant='outlined' onClick={() => handleAttachmentModalClose()}>
                  Close
                </Button>
                {user && user.type === 'vendor_admin' && (!assessmentStatus || assessmentStatus === 'Pre-Assessment') && (
                  <Button variant='contained' onClick={() => uploadAttachments()}>
                    Save
                  </Button>
                )}
              </StandardDialogActions>
            </StandardDialog>
            <StandardDialog
              title={`Assessor Finding for ${question.title}`}
              handleClose={handleAssessorFindingModalClose}
              isOpen={assessorFindingModal}
            >
              <AssessorFindingsForm
                user={user}
                handleSetShowPIDetails={handleSetShowPIDetails}
                question={question}
                assessorAnswer={assessorAnswer}
                assessmentStatus={assessmentStatus}
                assessorFindings={assessorFinding}
                onAssessorAnswerChange={(answer) => setAssessorAnswer(answer)}
                onAssessorFindingsChange={(findings) => setAssessorFinding(findings)}
              />
              {errorMessage.length > 0 && (
                <Typography color='error' sx={{ mt: 2 }}>
                  {errorMessage}
                </Typography>
              )}
              <StandardDialogActions>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Button
                  variant={
                    user &&
                    user.type === 'assessor' &&
                    assessmentStatus &&
                    ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
                      ? 'outlined'
                      : 'contained'
                  }
                  onClick={() => handleAssessorFindingModalClose()}
                >
                  {user &&
                  user.type === 'assessor' &&
                  assessmentStatus &&
                  ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
                    ? 'Cancel'
                    : 'Close'}
                </Button>
                {user &&
                  user.type === 'assessor' &&
                  assessmentStatus &&
                  ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1 && (
                    <Button variant='contained' color='primary' onClick={() => handleUpdateAssessorAnswer()}>
                      Save Finding
                    </Button>
                )}
                </Box>
              </StandardDialogActions>
            </StandardDialog>
            <StandardDialog
              title={`Remediate ${question.title}`}
              handleClose={handleRemediationModalClose}
              isOpen={remediationModal}
              >
              <RemediationForm
                subRemediations={subRemediations}
                stepIndex={stepIndex}
                handleStep={handleStep}
                subRemErr={subRemErr}
                setSubRemediations={setSubRemediations}
                handleRemediationModalClose={handleRemediationModalClose}
                assessorAnswer={assessorAnswer}
                setRemediationDateErr={setRemediationDateErr}
                remediationDateErr={remediationDateErr}
                user={user}
                question={question}
                origRemediationDate={assessmentQuestionsStatus && assessmentQuestionsStatus[question.id]?.remediationDate}
                remediationComment={remediationComment}
                remediationDate={remediationDate}
                vendorStatus={vendorStatus}
                vfiles={vfiles}
                subRemVFiles={subRemVFiles}
                errorMessage={errorMessage}
                onSubRemVFilesChange={handleSetSubRemNVFile}
                onRemediationCommentChange={(newRemediationComment) => setRemediationComment(newRemediationComment)}
                onRemediationDateChange={(newRemediationDate) => setRemediationDate(newRemediationDate)}
                onVendorStatusChange={handleVendorStatusChange}
                onVFilesChange={handleSetNVFile}
              />
              <StandardDialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                  <Button variant='outlined' onClick={() => handleRemediationModalClose()}>
                      {user && user.type === 'vendor_admin' ? 'Cancel' : 'Close'}
                    </Button>
                  {user && user.type === 'vendor_admin' && (
                    <Button variant='contained' color='primary' onClick={() => handleUpdateRemediation()}>
                      Update Remediation
                    </Button>
                  )}
                </Box>
              </StandardDialogActions>
            </StandardDialog>
            {company && (
              <StandardDialog
                title='Certification Upload'
                handleClose={handleUploadDocumentClose}
                isOpen={uploadDocumentOpen}
              >
                <CompanyCertificationForm
                  company={company}
                  handleClose={() => {
                    handleUploadDocumentClose();
                  }}
                  uploadCallback={companyCallback}
                  site={survey ? survey.site : undefined}
                  application={survey ? survey.application : undefined}
                />
              </StandardDialog>
            )}
            <StandardDialog
              title={`${
                companyCerts && companyCerts.length > 0 ? companyCerts[companyCerts.length - 1].certification.title : ''
              } Certification Uploaded`}
              handleClose={handleCertUploadedClose}
              isOpen={certUploadedOpen}
            >
              <Typography>
                You have successfully Self Attested. All questions satisfied by this{' '}
                {companyCerts && companyCerts.length > 0
                  ? companyCerts[companyCerts.length - 1].certification.title
                  : ''}{' '}
                will be automatically answered in the TPN MPA Best Practices Questionnaire.
              </Typography>
              <StandardDialogActions>
                <Button variant='outlined' onClick={() => handleCertUploadedClose()}>
                  Close
                </Button>
                <Link to='/profile/'>
                  <Button variant='contained' color='primary'>
                    Profile
                  </Button>
                </Link>
                <Link to={`/questionnaire/${newSurveyId}/`}>
                  <Button variant='contained' color='primary'>
                    TPN MPA Best Practices Questionnaire
                  </Button>
                </Link>
              </StandardDialogActions>
            </StandardDialog>
            {certUploadedOpen && <Confetti />}
          </>
      )}
    </>
  );
};

export default QuestionOnQuestionnaire;
