import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, FormControlLabel, FormGroup, Radio, RadioGroup, Typography, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import { Controller } from 'react-hook-form';
import { Question } from '../../interfaces/question.interface';
import { PIDetails } from '../../interfaces/assessment.interface';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CommentBuilder from '../Comments/CommentBuilder';
import RoundIconButton from './RoundIconButton';

const defaultStatusMap: { [key: string]: string } = {
  'compensating_control': 'Compensating Control: ',
  'not_implemented': 'Not Implemented: ',
  'implemented': 'Implemented: ',
  'not_applicable': 'Not Applicable: ',
};

export default function AssessorPIDetails({
  assessorFinding,
  canEdit,
  control,
  question,
  selectedCtrlAns,
  show,
  setShow,
  setSelectedCtrlAns,
  updateAssessorFindings,
}: {
  assessorFinding?: string,
  canEdit?: boolean,
  control: any,
  question: Question,
  selectedCtrlAns: { [key: number]: PIDetails },
  show: boolean,
  setSelectedCtrlAns: (value: { [key: number]: PIDetails }) => void,
  setShow: (value: boolean) => void,
  updateAssessorFindings: (findings: string) => void,
}) {
  const [singleSelectVal, setSingleSelectVal] = useState<string | undefined>(undefined);
  const [singleSelectQId, setSingleSelectQId] = useState<number | null>(null);
  const [locSelectedCtrlAns, setLocSelectedCtrlAns] = useState<{ [key: number]: PIDetails }>(() => {
    return JSON.parse(JSON.stringify(selectedCtrlAns)); // Deep copy of selectedCtrlAns
  });
  const theme = useTheme();
  const [localRemarks, setLocalRemarks] = useState<string>('');
  const [showCommentBuilder, setShowCommentBuilder] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleHideCommentBuilder = useCallback(() => {
    setShowCommentBuilder(false);
  }, [setShowCommentBuilder]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (!question) return;
    if (!selectedCtrlAns || Object.keys(selectedCtrlAns).length === 0) {
      setLocSelectedCtrlAns({});
      setSingleSelectQId(null);
      setSingleSelectVal(undefined);
    } else {
      setLocSelectedCtrlAns(prevState => ({
        ...prevState,
        ...JSON.parse(JSON.stringify(selectedCtrlAns)),
      }));
    }

    if (question.type === 'single_select' && Object.values(locSelectedCtrlAns).length > 0) {
      const selectedCtrlAnsVal = Object.values(locSelectedCtrlAns)[0];
      if (selectedCtrlAnsVal.compensatingOrNotApplicable) setSingleSelectVal(selectedCtrlAnsVal.compensatingOrNotApplicable);
    }
  }, [selectedCtrlAns, question.type]);

  const handleSaveAndContinue = useCallback(() => {
    if (localRemarks) {
      updateAssessorFindings(localRemarks);
      setLocalRemarks('');
    }
    setSelectedCtrlAns({ ...locSelectedCtrlAns });
    setShow(false);
  }, [locSelectedCtrlAns, setSelectedCtrlAns, setShow, localRemarks, updateAssessorFindings]);

  const handleCancel = useCallback(() => {
    // Reset state
    setLocalRemarks('');
    setSingleSelectQId(null);
    setSingleSelectVal(undefined);
    setLocSelectedCtrlAns(JSON.parse(JSON.stringify(selectedCtrlAns)));
    setShow(false);
  }, [selectedCtrlAns, setShow]);

  const handleSingleSelectVal = useCallback((event: React.SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    const newVal = (event.target as HTMLInputElement).value;
    setSingleSelectVal(newVal);
    if (!singleSelectQId) return;
    setLocSelectedCtrlAns(prevState => ({
      ...prevState,
      [singleSelectQId]: {
        ...prevState[singleSelectQId],
        compensatingOrNotApplicable: newVal,
      },
    }));
  }, [locSelectedCtrlAns, singleSelectQId]);

  const handleRadioChange = (id: number, value: string) => {
    setLocSelectedCtrlAns(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        compensatingOrNotApplicable: value,
      },
    }));
  };

  const handleSaveCommentBuilder = useCallback((comment: string) => {
    setLocalRemarks(comment);
  }, [setLocalRemarks]);

  const commentBuilderDefault = useMemo(() => {
    if (!showCommentBuilder) return '';
  
    if (localRemarks) {
      return localRemarks;
    }
  
    const filteredAnswers = question.answers.filter(
      ans => !['Fully Implemented', 'Not Implemented', 'Not Applicable'].includes(ans.text || ''),
    );
  
    const defaultBuild = filteredAnswers.map(ans => {
      const selectedCtrlAnsVal = locSelectedCtrlAns[ans.id];
      const plainText = selectedCtrlAnsVal
        ? defaultStatusMap[selectedCtrlAnsVal?.compensatingOrNotApplicable ?? ': ']
        : ': ';
      return `• ${ans.text} - ${plainText ?? ''}`;
    }).join('\n');
  
    return assessorFinding ? `${assessorFinding}\n\n${defaultBuild}` : defaultBuild;
  }, [locSelectedCtrlAns, question.answers, showCommentBuilder, assessorFinding, localRemarks]);

  const clearRadioAnswer = useCallback((id: number | null, isSingleSelect: boolean) => {
    if (!isSingleSelect && id) {
      setLocSelectedCtrlAns(prevState => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          compensatingOrNotApplicable: '',
        },
      }));
    } else {
      setSingleSelectVal('');
      if (!singleSelectQId) return;
      setLocSelectedCtrlAns(prevState => ({
        ...prevState,
        [singleSelectQId]: {
          ...prevState[singleSelectQId],
          compensatingOrNotApplicable: '',
        },
      }));
    }
  }, [locSelectedCtrlAns, singleSelectQId]);

  return (
    <>
      <CommentBuilder
        canEdit={canEdit}
        commentBuilderDefault={commentBuilderDefault}
        handleHideCommentBuilder={handleHideCommentBuilder}
        showCommentBuilder={showCommentBuilder}
        handleSaveCommentBuilder={handleSaveCommentBuilder}
      />
      <StandardDialog
        title={'Partially Implemented Assessor Findings'}
        handleClose={handleCancel}
        isOpen={show}
      >
        {question.type === 'single_select' && (
          <FormGroup>
            <Controller
              control={control}
              name='answer'
              render={({ field: { onChange, value } }) => {
                useEffect(() => {
                  setSingleSelectQId(value);
                }, []);
                return (
                  <>
                    <RadioGroup
                      aria-labelledby={question.title}
                      name='answer'
                      value={value ?? 0}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {question.answers.map((answer, key) => (
                        <FormControlLabel
                          sx={{
                            alignItems: 'flex-start',
                            '& .MuiRadio-root': {
                              padding: '0 .5em',
                            },
                            padding: '.5em 0',
                          }}
                          disabled
                          key={key}
                          value={answer.id}
                          control={<Radio />}
                          label={<Typography>{answer.text}</Typography>}
                        />
                      ))}
                    </RadioGroup>
                    <Box sx={{ marginLeft: '2.5em' }}>
                      <Box sx={{ display: 'flex', gap: '1em' }}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'semiBold', color: theme.palette.customThemeColors?.darkGrey }}
                        >
                          Findings
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <RadioGroup row name="implementationStatus" value={singleSelectVal ?? ''}>
                        <FormControlLabel
                            value="implemented"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Implemented"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                          <FormControlLabel
                            value="compensating_control"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Compensating Control"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                          <FormControlLabel
                            value="not_implemented"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Not Implemented"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                          <FormControlLabel
                            value="not_applicable"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Not Applicable"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                        </RadioGroup>
                        { canEdit && (
                          <RoundIconButton onClick={() => clearRadioAnswer(null, true)}/>
                        )}
                      </Box>
                    </Box>
                  </>
                );
              }}
            />
          </FormGroup>
        )}
        {question.type === 'multi_select' && (
          <FormGroup>
            <Controller
              control={control}
              name="answer"
              render={() => (
                <>
                  {question.answers
                    .filter(ans => !['Fully Implemented', 'Not Implemented', 'Not Applicable'].includes(ans.text || ''))
                    .map((answer, key) => (
                      <Box key={key} style={{ marginBottom: '1em' }}>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 'bold', color: theme.palette.customThemeColors?.darkGrey }}
                          >
                            {answer.text}
                          </Typography>
                        </Box>
                        <Box style={{ marginLeft: '2.5em', display: 'flex', alignItems: 'center' }}>
                          <RadioGroup row name="implementationStatus" value={locSelectedCtrlAns[answer.id]?.compensatingOrNotApplicable ?? ''}>
                          <FormControlLabel
                              disabled={!canEdit}
                              value="implemented"
                              onChange={() => handleRadioChange(answer.id, 'implemented')}
                              control={<Radio size="small" />}
                              label="Implemented"
                              labelPlacement="end"
                              sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                            />
                            <FormControlLabel
                              disabled={!canEdit}
                              value="compensating_control"
                              onChange={() => handleRadioChange(answer.id, 'compensating_control')}
                              control={<Radio size="small" />}
                              label="Compensating Control"
                              labelPlacement="end"
                              sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                            />
                            <FormControlLabel
                              disabled={!canEdit}
                              value="not_implemented"
                              onChange={() => handleRadioChange(answer.id, 'not_implemented')}
                              control={<Radio size="small" />}
                              label="Not Implemented"
                              labelPlacement="end"
                              sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                            />
                            <FormControlLabel
                              disabled={!canEdit}
                              value="not_applicable"
                              onChange={() => handleRadioChange(answer.id, 'not_applicable')}
                              control={<Radio size="small" />}
                              label="Not Applicable"
                              labelPlacement="end"
                              sx={{ marginRight: '1em', color: theme.palette.customThemeColors?.darkGrey }}
                            />
                          </RadioGroup>
                          { canEdit && answer.id in locSelectedCtrlAns && locSelectedCtrlAns[answer.id].compensatingOrNotApplicable && (
                            <RoundIconButton onClick={() => clearRadioAnswer(answer.id, false)}/>
                          )}
                        </Box>
                      </Box>
                    ))}
                </>
              )}
            />
          </FormGroup>
        )}
      <Box display="flex" justifyContent="start" width="100%">
        { canEdit && (
        <Tooltip
        title={
          <Box sx={{ padding: 1 }}>
            <Typography variant="h6" gutterBottom>
              Comment Builder
            </Typography>
            <Typography variant="body2">
            Auto-generate an editable Assessor Response based on your selected partially implemented findings
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setShowCommentBuilder(true)}
              sx={{ mt: 1 }}
            >
              Open Builder
            </Button>
          </Box>
        }
        arrow
        placement="top"
        onOpen={handleMouseEnter}
        onClose={handleMouseLeave}
      >
        <Button
          onClick={() => setShowCommentBuilder(true)}
          variant="contained"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            marginTop: '1em',
            marginBottom: '1em',
            '& .icon': {
              transform: isHovered ? 'translateX(-3px)' : 'none',
              transition: 'transform 0.3s ease',
            },
          }}
        >
          <AutoFixHighIcon
            className="icon"
            sx={{
              fontSize: 20,
            }}
          />
          Comment Builder
        </Button>
      </Tooltip>
        )}
    </Box>
    <StandardDialogActions>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
            onClick={handleCancel}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          {canEdit && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSaveAndContinue}
            >
              Continue
            </Button>
          )}
          </Box>
        </StandardDialogActions>
      </StandardDialog>
    </>
  );
}
