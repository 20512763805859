import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

import { AssessmentNotification } from '../../interfaces/assessment.interface';

const AssessmentNotificationsAlertIcon = ({ assessmentNotifications }: { assessmentNotifications: AssessmentNotification[] }) => {
  return (
        <>
        {assessmentNotifications.length > 0 && (
            <Tooltip title={`${assessmentNotifications.length} Past-Due Remediation${assessmentNotifications.length > 1 ? 's' : ''}`} placement='top' arrow>
                <IconButton
                sx={{
                  position: 'absolute',
                  top: '10px',
                  left: '-17px',
                }}
                >
                {/* Future logic for different icons based on notification type */}
                <AccessAlarmsIcon
                    sx={{
                      fontSize: 20,
                      color: 'warning.main',
                    }}
                />
                </IconButton>
            </Tooltip>
        )}
        </>
  );
};

export default AssessmentNotificationsAlertIcon;