import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PeopleIcon from '@mui/icons-material/People';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import './mainNavigation.css';
import { Button, Divider, Tooltip } from '@mui/material';
import { getHelpURL, getUserFromAuthSession } from '../../services/user';
import { HelpOutline } from '@mui/icons-material';
import { updateUser } from '../../app/store/userSlice';

function MainNavItemButton(
  href: string,
  pathName: string,
  displayName: string,
  Icon: any,
) {
  return (
    <Link className='mainNav' to={href} title={pathName}>
      <ListItemButton selected={pathName == href}>
        <ListItemIcon>
          <Icon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary={displayName} sx={{ color: 'white' }} />
      </ListItemButton>
    </Link>
  );
}

function SubNavItemButton(
  href: string,
  pathName: string,
  displayName: string,
  Icon?: any,
) {
  return (
    <Link className='subNav' to={href} title={pathName}>
      <ListItemButton selected={pathName == href}>
        {Icon && <ListItemIcon>
          <Icon sx={{ color: 'white' }} />
        </ListItemIcon>}
        <ListItemText primary={displayName} sx={{ color: 'white', pl: 3 }} />
      </ListItemButton>
    </Link>
  );
}

function QuestionnaireSubMenu() {
  const location = useLocation();
  return (
    <React.Fragment>
      {MainNavItemButton('/questionnaires', location.pathname, 'Questionnaires', DynamicFormIcon)}
    </React.Fragment>
  );
}

// NOTE: nav items are set to `#` until their components are built.
function VendorNavListItems(props: { open?:boolean }) {
  const { open } = props;
  const { user } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const location = useLocation();

  let userPolling:any = undefined;

  useEffect(() => {
    userPolling = user?.companies && user.companies.length > 0 && !user.companies[0].isPublished ? setInterval(function () {
      getUserFromAuthSession().then(responseUser => {
        if (responseUser.companies.length > 0 && responseUser.companies[0].isPublished) {
          dispatch(updateUser(responseUser));
          clearInterval(userPolling);
        }
      });
    }, 30000) : undefined;

    return (() => {
      clearInterval(userPolling);
    });
  }, [user]);

  return (
    <React.Fragment>{user?.type && <>
      {user.type === 'content_owner' && user.companies.length > 0 && user.companies[0].isPublished && MainNavItemButton('/dashboard', location.pathname, 'Dashboard', SpeedOutlinedIcon)}
      {open && <Box className={user?.type !== 'content_owner' ? `topNav ${location.pathname.indexOf('profile') > -1 ? 'active' : ''}` : ''}>
        {MainNavItemButton('/profile', location.pathname, 'Profile', AccountCircleOutlinedIcon)}
        {user.type === 'vendor_admin' && <>
          {SubNavItemButton('/profile/services', location.pathname, 'Services')}
          {SubNavItemButton('/profile/sites', location.pathname, 'Sites')}
          {SubNavItemButton('/profile/applications', location.pathname, 'Applications')}
          {SubNavItemButton('/profile/certifications', location.pathname, 'Certifications')}
        </>}
        {(user.type === 'vendor_admin' || user.type === 'assessor') && SubNavItemButton('/profile/assessments', location.pathname, 'Manage Assessments')}
        {user.type !== 'content_owner' && SubNavItemButton('/profile/documents', location.pathname, 'Documents')}
      </Box>}
      {!open && MainNavItemButton('/profile', location.pathname, 'Profile', AccountCircleOutlinedIcon)}
      {MainNavItemButton('/registry', location.pathname, 'Companies', FolderSharedOutlinedIcon)}
      {MainNavItemButton('/applications', location.pathname, 'Applications', AssessmentOutlinedIcon)}
      {MainNavItemButton('/member-resources', location.pathname, 'Member Resources ', LocalLibraryIcon)}
    </>}</React.Fragment>
  );
}

function TPNAdminNavListItems() {
  const location = useLocation();
  return (
    <React.Fragment>
      {MainNavItemButton('/dashboard', location.pathname, 'Dashboard', SpeedOutlinedIcon)}
      {MainNavItemButton('/admin', location.pathname, 'Admin', PeopleIcon)}
      {QuestionnaireSubMenu()}
      {MainNavItemButton('/registry', location.pathname, 'Companies', FolderSharedOutlinedIcon)}
      {MainNavItemButton('/applications', location.pathname, 'Applications', AssessmentOutlinedIcon)}
      {MainNavItemButton('/member-resources', location.pathname, 'Member Resources', LocalLibraryIcon)}
    </React.Fragment>
  );
}

export function MainListItems(props: { open?: boolean }) {
  const { user } = useAppSelector(state => state.user);

  return (
    <React.Fragment>
      {user?.type && <>
        {user?.type == 'tpn_admin' ? <TPNAdminNavListItems /> : <VendorNavListItems open={props.open} />}
        <Divider sx={{ mt: 2, mb: 2, borderColor: 'rgba(255,255,255,.4)' }} />
        <Box sx={{ textAlign: 'center' }}><Tooltip title='Need Support?'><a target="_blank" href={getHelpURL(user?.type)} rel="noreferrer"><Button fullWidth variant='contained' onClick={()=> { return false; }}><HelpOutline sx={{ mr: props.open ? 1 : 0 }} />{props.open && 'Need Support?'}</Button></a></Tooltip></Box>
        <Box sx={{ textAlign: 'center', mt: 1 }}><Tooltip title='TPN QRGs'><a target="_blank" href="https://www.ttpn.org/links-resources/" rel="noreferrer"><Button fullWidth variant='contained' onClick={()=> { return false; }}><HelpOutline sx={{ mr: props.open ? 1 : 0 }} />{props.open && 'TPN How-To Guides'}</Button></a></Tooltip></Box>
      </>}
    </React.Fragment>
  );
}
