import React, { useState, useCallback, useEffect } from 'react';
import { Answer } from '../../interfaces/answer.interface';
import { Question } from '../../interfaces/question.interface';
import update from 'immutability-helper';

import { DataRow, DataCell } from '../Tables/DataList';
import AnswerListItem from './AnswerListItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch } from '../../app/hooks';
import { fetchAll } from '../../app/store/questionnaireSlice';
import { IncludedInLogic } from '../Questions/Question';



export default function AnswerList(props: { includedInLogic: IncludedInLogic[], answers: Answer[], canEdit?: boolean, questionUpdate: any, question: Question }) {
  const [answers, setAnswers] = useState(props.answers);
  const dispatch = useAppDispatch();
  const moveAnswer = useCallback((dragIndex: number, hoverIndex: number) => {
    setAnswers((prevAnswers: Answer[]) =>
      update(prevAnswers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevAnswers[dragIndex] as Answer],
        ],
      }),
    );
  }, []);

  const dropAnswer = () => {
    props.questionUpdate({ answers });
  };

  const removeAnswer = (answerId:number) => {
    const answerIndex = answers.findIndex(answer => answer.id === answerId);
    let newAnswerList = [ ...answers ];
    newAnswerList.splice(answerIndex, 1);
    props.questionUpdate({ answers: newAnswerList });
  };

  useEffect(() => {
    if (!props.answers || typeof props.answers[0] === 'number') return;
    setAnswers(props.answers);
  }, [props.answers]);

  useEffect(() => {
    dispatch(fetchAll());
  }, []);

  return (
    <>
      <DataRow header>
        <DataCell xs={7}>Text</DataCell>
        <DataCell xs={2}>.</DataCell>
        <DataCell xs={3}>Is Compliant</DataCell>
      </DataRow>
      <DndProvider backend={HTML5Backend}>
        { answers.map((answer: Answer, idx: number) => (
          <AnswerListItem
            canEdit={props.canEdit}
            includedInLogic={props.includedInLogic}
            key={answer.id}
            answer={answer}
            question={props.question}
            idx={idx}
            moveItem={moveAnswer}
            dropItem={dropAnswer}
            removeAnswer={removeAnswer}
            updateQuestion={props.questionUpdate}
          />
        ))}
      </DndProvider>
    </>
  );
}