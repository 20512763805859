import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import { Box, Button, Typography, Grid, List, ListItem, ListItemText, Card, CardContent, CardMedia, TextField, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StandardDialogActions } from '../Modals/StandardDialog';
import StandardDialog from '../Modals/StandardDialog';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SearchIcon from '@mui/icons-material/Search';

const MemberResources = () => {
  const [showEduCenter, setShowEduCenter] = useState(false);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const sections: { [key: string]: any[] } = {
    'Policies': [
      { id: '1', title: 'Business Continuity Plan', description: 'Learn about strategies for business continuity.', datePublished: '2023-09-12' },
      { id: '2', title: 'Disaster Recovery Plan', description: 'Recovery from catastrophic events.', datePublished: '2023-09-20' },
      { id: '3', title: 'Incident Response', description: 'Effective response to incidents.', datePublished: '2023-08-25' },
      { id: '4', title: 'Risk Management', description: 'Explore the risk management process.', datePublished: '2023-10-01' },
      { id: '5', title: 'Training & Awareness', description: 'Training programs and awareness strategies.', datePublished: '2023-07-15' },
    ],
    'Templates': [
      { id: '6', title: 'Template 1', description: 'Description for template 1.', datePublished: '2023-07-25' },
      { id: '7', title: 'Template 2', description: 'Description for template 2.', datePublished: '2023-08-10' },
    ],
  };

  // const colors = ['#FF7043', '#FFA726', '#FFCA28', '#66BB6A', '#42A5F5', '#AB47BC'];
  // const gradientColorsAS = [
  //     { start: '#6A1B9A', end: '#8E24AA' },  // Subtle Purple gradient
  //     { start: '#FF8A65', end: '#FFB74D' },  // Subtle Orange gradient
  //     { start: '#81C784', end: '#64B5F6' },  // Soft Green to Blue gradient
  //     { start: '#BA68C8', end: '#AB47BC' },  // Light Purple gradient
  //     // Add more color transitions as needed
  // ];

  // const gradientColorsTwo = [
  //     { start: '#6A1B9A', end: '#C158DC' },  // Rich Purple to Light Purple
  //     { start: '#FF8A65', end: '#FFD54F' },  // Warm Orange to Soft Yellow
  //     { start: '#4CAF50', end: '#81C784' },  // Vibrant Green to Light Green
  //     { start: '#42A5F5', end: '#7E57C2' },  // Blue to Soft Violet
  //     { start: '#BA68C8', end: '#AB47BC' },
  //     // Add more color transitions as needed
  // ];

  const gradientColors = [
    { start: '#2C3E50', end: '#4CA1AF' },  // Dark Blue to Teal
    { start: '#34495E', end: '#2C3E50' },  // Slate Gray to Charcoal Blue
    { start: '#1F1C2C', end: '#928DAB' },  // Dark Violet to Soft Gray
    { start: '#2E4053', end: '#4B79A1' },  // Dark Slate to Steel Blue
    { start: '#0F2027', end: '#203A43' },  // Dark Teal to Deep Ocean Blue
    { start: '#3A6073', end: '#16222A' },  // Slate Blue to Dark Teal
  ];
    
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedDocument !== null) setSelectedDocument(null);
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter sections based on the search query
  const filteredSections = expandedSection
    ? sections[expandedSection].filter((item) =>
      item.title.toLowerCase().includes(searchQuery) || item.description.toLowerCase().includes(searchQuery),
    )
    : [];

  const handleSectionClick = (section: string) => {
    setExpandedSection(section);
    setSelectedDocument(null);
    setSearchQuery('');
  };

  const handleCloseEduCenter = () => {
    setShowEduCenter(false);
    setExpandedSection('Policies');
    setSelectedDocument(null);
    setSearchQuery('');
  };

  useEffect(() => {
    handleSectionClick('Policies');
  }, []);

  const handleCardClick = (document: any) => {
    setSelectedDocument(document);
  };

  const handleBackClick = () => {
    setSelectedDocument(null);
  };

  return (
        <Layout pageTitle="Member Resources">
            <Box component="section" sx={{ maxWidth: '1000px', px: 4, py: 2 }}>
                <Typography variant="h5" sx={{ my: 2, fontWeight: 500 }}>
                    Member Resource Center
                </Typography>
            </Box>

            <Box
                component="section"
                sx={{
                  maxWidth: '1000px',
                  padding: 0,
                  display: 'flex',
                  flexWrap: 'wrap',
                  borderRadius: '4px',
                  alignItems: 'stretch',
                  border: '1px solid #e1e3e5',
                }}
            >
                <Box
                    sx={{
                      flex: 1,
                      padding: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                >
                    <Typography variant="body2" sx={{ my: 1 }}>
                        Explore a wide range of documents, templates, and policies. Find resources that can help you navigate various aspects of your membership.
                    </Typography>
                    <Button
                        variant="outlined"
                        sx={{ my: 2, paddingX: 2, paddingY: 1, width: '100%', maxWidth: '300px' }}
                        onClick={() => setShowEduCenter(true)}
                    >
                        Explore Member Resource Center
                    </Button>
                </Box>
                <Box
                    sx={{
                      flex: 1,
                      ml: 1,
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/AdobeEdited.png)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: 'flex',
                      alignItems: 'stretch',
                      justifyContent: 'center',
                      borderTopLeftRadius: '80px',
                      borderBottomLeftRadius: '80px',
                    }}
                />
            </Box>

            <Accordion sx={{ maxWidth: '1000px', padding: 2, my: 4, border: '1px solid #e1e3e5', backgroundColor: 'inherit' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: 'inherit' }}
                >
                    <Typography variant="h5">Getting Started with Member Resources</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Watch the explainer video below to understand how to navigate and make the most of the Member Resource Center.
                    </Typography>
                    <Box
                        component="iframe"
                        sx={{ width: '100%', height: '500px', border: 'none' }}
                        src="https://www.youtube.com/embed/exampleVideoID"
                        title="Getting Started with Member Resources"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </AccordionDetails>
            </Accordion>
            <StandardDialog maxWidth="xl" title="Member Resource Center" isOpen={showEduCenter} handleClose={handleCloseEduCenter}>
                <Grid container>
                    <Grid item xs={2} sx={{ borderRight: '1px solid #f1f4f6', padding: 2 }}>
                        <Typography variant="h6">Library</Typography>
                        <List component="nav">
                            <ListItem
                                button
                                onClick={() => handleSectionClick('Policies')}
                                sx={{
                                  borderRadius: '4px',
                                  backgroundColor: expandedSection === 'Policies' ? '#ECEFF1' : 'inherit',
                                  '&:hover': {
                                    backgroundColor: '#f1f4f6',
                                  },
                                }}
                            >
                                <ListItemText primary="Policies" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => handleSectionClick('Templates')}
                                sx={{
                                  borderRadius: '4px',
                                  backgroundColor: expandedSection === 'Templates' ? '#ECEFF1' : 'inherit',
                                  '&:hover': {
                                    backgroundColor: '#f1f4f6',
                                  },
                                }}
                            >
                                <ListItemText primary="Templates" />
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item xs={10} sx={{ padding: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                            <TextField
                                variant="outlined"
                                placeholder="Search by title or description..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                  startAdornment: <SearchIcon />,
                                  sx: { height: '45px' },
                                }}
                                sx={{
                                  width: '50%',
                                  minWidth: '300px',
                                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: '#e8e8e8',
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: 'transparent',
                                    },
                                  },
                                  borderRadius: '4px',
                                }}
                            />
                            <Button
                                variant="text"
                                startIcon={<ReviewsIcon />}
                                sx={{ ml: 2 }}
                                onClick={() => alert('Feedback clicked')}
                            >
                                Feedback
                            </Button>
                        </Box>
                        {!selectedDocument ? (
                            <Box>
                                <Typography variant="body1" sx={{ mb: 2, fontWeight: 500 }}>
                                    {expandedSection}
                                </Typography>
                                <Grid container spacing={3}>
                                    {filteredSections.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={3} key={item.id}>
                                            <Card
                                                sx={{
                                                  height: '100%',
                                                  maxHeight: 375,
                                                  border: '1px solid #f5f5f5',
                                                  padding: 2,
                                                  cursor: 'pointer',
                                                  transition: 'transform 0.2s, box-shadow 0.2s',
                                                  '&:hover': {
                                                    transform: 'scale(1.02)',
                                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                                  },
                                                }}
                                                onClick={() => handleCardClick(item)}
                                            >
                                                <Box
                                                    sx={{
                                                      background: `linear-gradient(to bottom left, ${gradientColors[index % gradientColors.length].start}, ${gradientColors[index % gradientColors.length].end})`,
                                                      padding: 1,
                                                      borderRadius: 1,
                                                    }}
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        height="130"
                                                        image={`${process.env.PUBLIC_URL}/assets/Screenshot-${index + 1}.png`}
                                                        alt="PDF preview"
                                                        sx={{ objectFit: 'contain' }}
                                                    />
                                                </Box>
                                                <CardContent
                                                    sx={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      justifyContent: 'space-between',
                                                      flex: 1,
                                                      height: 'calc(100% - 140px)',
                                                      pt: 2,
                                                      px: 1,
                                                    }}
                                                >
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Typography variant="h6" sx={{ fontWeight: '500' }}>
                                                            {item.title}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                                                            {item.description}
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ justifyContent: 'space-between' }}>
                                                        <Typography variant="caption" sx={{ color: 'gray' }}>
                                                            <DownloadIcon sx={{ fontSize: 'small', verticalAlign: 'middle', mr: 0.5 }} />{Math.floor(Math.random() * 1000)}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>

                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ) : (
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBackClick}>
                                        Back
                                    </Button>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', my: 2, gap: 15 }}>
                                    <Box>
                                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            {selectedDocument.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 2 }}>
                                            This document provides an in-depth look into the key aspects of {selectedDocument.title}, offering a comprehensive overview of the subject.
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => alert('Downloading file...')}
                                            startIcon={<DownloadIcon />}
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                      mb: 4,
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'flex-start',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        sx={{
                                          height: '400px',
                                          objectFit: 'contain',
                                          border: '1px solid #f5f5f5',
                                        }}
                                        src={`${process.env.PUBLIC_URL}/assets/Screenshot-${selectedDocument.id}.png`}
                                        alt="Document preview"
                                    />
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>

                <StandardDialogActions>
                    <Button variant="outlined" onClick={() => setShowEduCenter(false)}>Close</Button>
                </StandardDialogActions>
            </StandardDialog>
        </Layout>

  );
};

export default MemberResources;
