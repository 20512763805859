import React, { useState, ChangeEvent, useEffect, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Assessment, AssessmentSurvey } from '../../interfaces/assessment.interface';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useForm, FieldValues } from 'react-hook-form';

import { createAssessment, updateAssessment } from '../../app/store/assessmentSlice';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import User, { AssessorCompany, Company } from '../../interfaces/users.interface';
import throttle from 'lodash/throttle';
import getAuthSession from '../../services/auth';
import {
  TextField,
  InputAdornment,
  Grid,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import axios, { AxiosResponse } from 'axios';
import { Survey } from '../../interfaces/survey.interface';
import { createMultipleAssessmentSurveys, deleteMultipleAssessmentSurveys, newAssessorAssessmentAlert, updateMultipleAssessmentSurveys } from '../../services/assessments';
import SurveySelectList from '../Vendors/Forms/SurveySelectList';
import { updateWorkflow } from '../../app/store/workflowSlice';
import { fetchWorkflows } from '../../services/workflow';
import { Workflow } from '../../interfaces/workflow.interface';
import { fetchSurvey } from '../../services/surveys';

export default function AssessmentForm(props: { assessment?: Assessment, modalOpen: boolean, company: Company, closeCallback: any }) {
  const {
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      assessor: props.assessment?.assessor ? (props.assessment.assessor as Company).id : undefined,
      assignedAssessor: props.assessment?.assignedAssessor ? (props.assessment.assignedAssessor as User).id : undefined,
    } as {
      assessor?: number,
      assignedAssessor?: number,
    },
  });
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.user);
  const workflow = useAppSelector(state => state.workflow?.workflows?.find(wf => wf.assessment === props.assessment?.id));
  const [filteredSurveys, setFilteredSurveys] = useState<Survey[] | undefined>(undefined);
  const [selectedSurveys, setSelectedSurveys] = useState<Survey[]>([]);
  const [newSelectedSurveys, setNewSelectedSurveys] = useState<Survey[]>([]);
  const [newSelectedAssessmentSurveys, setNewSelectedAssessmentSurveys] = useState<AssessmentSurvey[]>([]);
  const [errorText, setErrorText] = useState('');
  const [workflows, setWorkflows] = useState<undefined | Workflow[]>(undefined);

  const surveysCallBack = (surs:Survey[], assSurs:AssessmentSurvey[]) => {
    setNewSelectedSurveys(surs);
    setNewSelectedAssessmentSurveys(assSurs);
  };


  const [modalOpen, setModalOpen] = useState(props.modalOpen);
  const [itemList, setItemList] = useState<AssessorCompany[]>([]);
  const [JWToken, setJWToken] = useState('');
  const userRef = useRef(null);

  const [showInPersonModal, setShowInPersonModal] = useState<boolean>(false);

  const handleShowInPersonModal = useCallback(() => {
    setShowInPersonModal(true);
  }, [setShowInPersonModal]);

  useEffect(() => {
    if (!props.assessment || !modalOpen) return;
    (props.assessment.surveys as  AssessmentSurvey[]).forEach(sur => {
      fetchSurvey(typeof sur.survey === 'number' ? sur.survey : sur.survey.id).then(nsur => {
        setFilteredSurveys(fs => fs ? [...fs, nsur] : [nsur]);
        setSelectedSurveys(fs => fs ? [...fs, nsur] : [nsur]);
      });
    });
  }, [props.assessment, modalOpen]);

  useEffect(() => {
    if (!props.assessment || !JWToken || !modalOpen) return;
    reset({
      assessor: props.assessment?.assessor ? (props.assessment.assessor as Company).id : undefined,
      assignedAssessor: props.assessment?.assignedAssessor ? (props.assessment.assignedAssessor as User).id : undefined,
    });

    if (!filteredSurveys) return;

    let sSurveys:Survey[] = [];
    (props.assessment.surveys as AssessmentSurvey[]).forEach(sur => {
      const fSurvey = filteredSurveys.find(nsur => nsur.id === (sur.survey as Survey).id);
      if (fSurvey) sSurveys.push(fSurvey);
    });
  }, [props.assessment, JWToken, modalOpen]);

  const handleFetchWorkflows = async () => {
    const wfs = await fetchWorkflows({ status: 'submittedForAssessment', service_provider: props.company.id });
    setWorkflows(wfs);
    if (!wfs.length) setFilteredSurveys([]);
    wfs.filter(wf => wf.bpSurvey).forEach(wf => fetchSurvey(wf.bpSurvey!).then(s => setFilteredSurveys(fs => fs ? fs.find(ffs => ffs.id === s.id) ? [...fs] : [...fs, s] : [s])));
  };

  useEffect(() => {
    setModalOpen(props.modalOpen);
    if (props.modalOpen) handleFetchWorkflows();
    if (!props.modalOpen) {
      reset({
        assessor: undefined,
        assignedAssessor: undefined,
      });
      setFilteredSurveys(undefined);
      setWorkflows(undefined);
      setSelectedSurveys([]);
      setItemList([]);
    }

  }, [props.assessment, JWToken, modalOpen]);

  useEffect(() => {
    setModalOpen(props.modalOpen);
    if (props.modalOpen) handleFetchWorkflows();
    if (!props.modalOpen) {
      reset({
        assessor: undefined,
        assignedAssessor: undefined,
      });
      setFilteredSurveys(undefined);
      setWorkflows(undefined);
      setSelectedSurveys([]);
      setItemList([]);
    }
  }, [props.modalOpen]);

  const onSubmit = async (data: FieldValues) => {
    if (newSelectedSurveys.length === 0) { setErrorText('You must Select a Site or Application'); return; }
    if (!data.assessor) { setErrorText('You must Select an Assessment Company'); return; }
    if (!data.assignedAssessor) { setErrorText('You must Select an Assessor'); return; }
    setErrorText('');

    if (!props.assessment) {
      const assessmentSurveys = await createMultipleAssessmentSurveys(newSelectedAssessmentSurveys.filter(assessmentSurvey => newSelectedSurveys.findIndex(nss => nss.id === assessmentSurvey.survey) > -1));
      dispatch(createAssessment({ ...data, company: props.company.id, surveys: assessmentSurveys, status: 'Assigned' })).then(async (action) => {
        if (workflows) {
          for (const wf of workflows) {
            const assSur = assessmentSurveys.find(as => (typeof as.survey === 'number' ? as.survey : as.survey.id) === wf.bpSurvey);
            if (!assSur) continue; // Skip to the next workflow if no assessment survey is found
        
            await dispatch(updateWorkflow({
              id: wf.id,
              assessmentSurvey: assSur.id,
              status: 'Assigned',
              assessor: data.assessor,
              assignedAssessor: data.assignedAssessor,
              assessment: (action.payload as Assessment).id,
            }));
          }
        }
      });
      newAssessorAssessmentAlert(props.company.id!, data.assessor);
    } else {
      const assessmentSurveysToDelete = (props.assessment.surveys as AssessmentSurvey[]).filter(curSur => newSelectedSurveys.findIndex(survey => (curSur.survey as Survey).id === survey.id) === -1);
      const assessmentSurveysToCreate = newSelectedSurveys.filter(curSur => ((props.assessment as Assessment).surveys as AssessmentSurvey[]).filter(curAssSur => (curAssSur.survey as Survey).id === curSur.id).length === 0);

      if (assessmentSurveysToDelete && assessmentSurveysToDelete.length > 0) deleteMultipleAssessmentSurveys(assessmentSurveysToDelete.map(assSur => (assSur.id as number)));
      let newAssessmentSurveys:any = [];
      if (assessmentSurveysToCreate && assessmentSurveysToCreate.length > 0) newAssessmentSurveys = await createMultipleAssessmentSurveys(newSelectedAssessmentSurveys.filter(assessmentSurvey => assessmentSurveysToCreate.findIndex(nss => nss.id === assessmentSurvey.survey) > -1));

      if (newSelectedSurveys.length > assessmentSurveysToDelete.length + assessmentSurveysToCreate.length ) {
        const updateSurveys = newSelectedSurveys.filter(survey => assessmentSurveysToDelete.findIndex(delsur => delsur.id === survey.id) === -1 && assessmentSurveysToCreate.findIndex(newsur => newsur.id === survey.id) === -1);
        if (updateSurveys && updateSurveys.length > 0) updateMultipleAssessmentSurveys(newSelectedAssessmentSurveys.filter(assessmentSurvey => updateSurveys.findIndex(us => us.id === assessmentSurvey.survey) > -1));
      }

      const postAssessmentSurveys = [ ...props.assessment.surveys, ...newAssessmentSurveys ].filter(assSur => assessmentSurveysToDelete.map(assdel => assdel.id).indexOf(assSur.id) === -1);
      dispatch(updateAssessment({
        ...data,
        company: (props.assessment.company as Company).id,
        surveys: postAssessmentSurveys,
        status: props.assessment.status.toLowerCase() === 'rejected' ? 'Assigned' : props.assessment.status,
        id: props.assessment.id,
      }));
      if (workflow) dispatch(updateWorkflow({ id: workflow.id, status: props.assessment.status.toLowerCase() === 'rejected' ? 'Assigned' : props.assessment.status, ...data }));

      if (props.assessment.status.toLowerCase() === 'rejected') newAssessorAssessmentAlert(props.company.id!, data.assessor);
    }
    reset({
      assessor: undefined,
      assignedAssessor: undefined,
    });
    setSelectedSurveys([]);
    setItemList([]);
    props.closeCallback();
  };

  useEffect(() => {
    getAuthSession().then((session) => {
      setJWToken(session.getIdToken().getJwtToken());
    });
  });

  const fetchItems =  (searchText: string) => {
    const url = user?.email.includes('@motionpictures.org')
      ?  `${process.env.REACT_APP_BASE_API}/companies/assessor-companies/?limit=9999&search=${searchText}` // Fetch all assessors for MPA users
      : `${process.env.REACT_APP_BASE_API}/companies/assessor-companies/?limit=9999&has_assessor_level=true&search=${searchText}`;
    if (!searchText || !JWToken) return;
    try {
      axios.get(
        url,
        {
          headers: { 'Authorization': `Bearer ${JWToken}` },
        },
      ).then((response: AxiosResponse) => {
        setItemList(response.data.results as AssessorCompany[]);
      });
    } catch (error: any) {
      console.error(error.response.data);
    }
  };

  const handleAssessorChange = (event: any, item: any | null) => {
    if (!item) return;
    setValue('assessor', item.id);
    setValue('assignedAssessor', item.employees.length > 0 ? item.employees[0].id : undefined);
    if (userRef && userRef.current) {
      const autoCRef = (userRef.current as HTMLElement).getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
      if (autoCRef) autoCRef.click();
    }
  };

  const throttledFetchItems = throttle(fetchItems, 2500, { leading: true, trailing: true });

  const handleInputChange = async (event: ChangeEvent<{ value: unknown }>) => {
    await throttledFetchItems(event.target.value as string);
  };

  return (<>
    {props.company && filteredSurveys &&
    <React.Fragment>
      <StandardDialog title={(props.assessment ? 'Edit' : 'Create New') + ' Assessment' } isOpen={modalOpen} handleClose={() => props.closeCallback()}>
      {( (props.company.isPublished && user?.type === 'vendor_admin') || user?.type == 'tpn_admin') && <Box component='form' sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <SurveySelectList surveys={filteredSurveys ? filteredSurveys : []} selectedSurveys={selectedSurveys} company={props.company} assessment={props.assessment} surveysCallBack={surveysCallBack} handleShowInPersonModal={handleShowInPersonModal}/>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id='assessor'
                fullWidth
                options={itemList}
                getOptionLabel={(option) => option?.assessorLevel ? option.name + ' - ' + option.assessorLevel.descriptor : option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                onChange={handleAssessorChange}
                disableClearable={true}
                defaultValue={props.assessment ? (props.assessment.assessor as AssessorCompany) : undefined}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleInputChange}
                  disabled={user?.type === 'assessor'}
                  placeholder='Search Assessors'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                      {params.InputProps.endAdornment}
                    </>),
                  }}
                /> }
              />
            </Grid>
          </Grid>
          {errorText.length > 0 && <Typography color="error" variant="caption">{errorText}</Typography>}
          <StandardDialogActions>
            <Button variant="outlined" onClick={() => props.closeCallback()}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </StandardDialogActions>
        </Box>}
        {(props.company.isPublished && user?.type != 'tpn_admin') && <Box>
          <Typography>Your membership is not currently active. Please ensure you have paid the membership fee - and, if applicable, signed the TPN membership agreement.</Typography>
          <StandardDialogActions>
            <Button variant="outlined" onClick={() => props.closeCallback()}>Close</Button>
          </StandardDialogActions>
        </Box>}
      </StandardDialog>
      <StandardDialog title='Notice' isOpen={showInPersonModal} handleClose={() => setShowInPersonModal(false)} maxWidth="sm">
        <Typography>Studios often prefer an on-site assessment. Please check with your Studio customers if you are unsure.</Typography>
        <StandardDialogActions>
            <Button variant="outlined" onClick={() => setShowInPersonModal(false)}>Close</Button>
        </StandardDialogActions>
      </StandardDialog>
    </React.Fragment>
    }
  </>
  );
}
